import ReactCalendar, { CalendarTileProperties } from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import styled from '@emotion/styled'
import { useQuery } from 'react-query'
import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye'
import CloseIcon from '@mui/icons-material/Close'
import {
  formatNextDate,
  formatPrevDate,
  formatDashDate
} from '../../functions/helpers'
import { getHoliday } from '../../functions/apis'
import { mediaQuery } from '../../functions/hooks'

const CustomReactCalendar = styled(ReactCalendar)`
  /* stylelint-disable */
  padding: 10px;
  width: 100%;
  max-width: 100%;
  height: 100%;
  line-height: 1.125em;
  background: white;
  border-radius: 10px;
  border: none;

  /* react-calendar--doubleViewは現在使用していない */
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }

  /* 各タイルはボタンでできているので全てのタイルに適応される */
  button {
    margin: 0;
    border: 0;
    outline: 2px solid white;
  }
  button:enabled:hover {
    cursor: pointer;
  }

  /* ヘッダーの月を変更するナビゲーション */
  .react-calendar__navigation {
    display: flex;
    justify-content: center;
    height: 44px;
    margin-bottom: 1em;
  }
  /*カレンダーの年を押下させない*/
  .react-calendar__navigation__label {
    /* flex-growを上書きすることで中央寄せできる*/
    /* flex-grow: initial !important; */
    pointer-events: none;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  .react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }

  /* 上部の曜日のハンドリング */
  .react-calendar__month-view__weekdays {
    font-size: 0.75em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
  }

  /* カレンダーに表示されている週末のタイル */
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  /* カレンダーに表示されている前月もしくは翌月のタイル */
  .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
    background: #f9f9f9;
  }
  /* ヘッダーの月のナビゲーションを選択した際の月一覧と年一覧の表示 */
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }

  /* 表示されている月の日付タイルのレイアウト */
  .react-calendar__tile {
    position: relative;
    height: 76px;

    /* 表示されている月の日付タイルの「日数」レイアウト */
    > abbr {
      position: absolute;
      top: 4px;
      right: 4px;
      z-index: 1;
      ${mediaQuery('sp')} {
        position: static;
      }
    }
  }
  // 土曜日の文字を青色に
  .react-calendar__tile:nth-child(7n) {
    color: #0066ff;
  }
  // 来月の文字はグレーに
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575 !important;
  }

  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }

  .react-calendar__tile:enabled:hover {
    background-color: #e6e6e6;
  }
  .react-calendar__tile:enabled:focus {
    /* background-color: white; */
    background: #76baff;
  }

  /* 今日の日付のタイル */
  .react-calendar__tile--now {
    /* color: black;
    background: #ffff76; */
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    color: black;
    background: #ffffa9;
  }

  .react-calendar__tile--hasActive {
    background: #a9d4ff;
    color: black;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }

  /* 選択された日付のタイル */
  .react-calendar__tile--active {
    background: #76baff;
    color: black;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    /* none; */
  }

  .react-calendar--selectRange .react-calendar__tile--hover {
    /* background-color: #e6e6e6; */
  }
`

export type CalenderProps = {
  value?: Date | null
  onChange: (e: Date) => void
}

// jaのロケールの設定が週頭が月曜始まりになっているので日曜始まりにする

const tempHoliday = [
  '2022-12-26',
  '2022-12-27',
  '2022-12-28',
  '2022-12-29',
  '2022-12-30',
  '2023-01-03',
  '2023-01-04'
]

// 2023/10/31で、マルデリが終了するため下記のCustomReactCalendarコンポーネント内maxDateに2023/10/31を設定する。

const defaultMaxDate = formatNextDate(new Date(), 30)
const maruderiLastDate = new Date('2023/10/31')

const inputtableMaxDate = (
  defaultMaxDate: Date,
  maruderiLastDate: Date
): Date => {
  if (defaultMaxDate > maruderiLastDate) {
    return maruderiLastDate
  }
  return defaultMaxDate
}

// format https://stackoverflow.com/questions/71014237/how-to-convert-date-from-react-calendar-in-the-dd-mm-yyyy-format
export const Calender: React.FC<CalenderProps> = ({ value, onChange }) => {
  const { data: holiday } = useQuery('getHoliday', getHoliday)

  const checkDisabledDate = (date: Date) => {
    const dashDate = formatDashDate(date)

    if (date > maruderiLastDate) {
      return true
    }

    // FIXME:後ほど削除したい
    if (tempHoliday.includes(dashDate)) {
      return true
    }
    // 週末かどうかの判定を行う
    const weekday = format(date, 'E', { locale: ja })
    if (['土', '日'].includes(weekday)) {
      return true
    }
    // 祝日であるかの判定を行う
    if (holiday?.data) {
      const holidays = Object.keys(holiday.data)
      const result = holidays.find((holiday) => holiday === dashDate)
      return Boolean(result)
    }
    return false
  }
  const renderImpossibleDays = (props: CalendarTileProperties) => {
    return checkDisabledDate(props.date) ? (
      <CloseIcon color="error" />
    ) : (
      <PanoramaFishEyeIcon color="primary" />
    )
  }
  return (
    <div>
      <CustomReactCalendar
        calendarType="US"
        locale="ja"
        onChange={onChange}
        value={value}
        maxDate={inputtableMaxDate(defaultMaxDate, maruderiLastDate)}
        minDate={formatPrevDate(new Date(), -1)}
        minDetail="year"
        tileDisabled={({ date }) => checkDisabledDate(date)}
        tileContent={renderImpossibleDays}
      />

      {/* <ReactCalendar
        onClickDay={onClickDay}
        tileContent={renderImpossibleDays}
        next2Label={null}
        prev2Label={null}
        tileDisabled={renderResidentDays}
        tileClassName={addClassHasResidentJobDays}
      /> */}
    </div>
  )
}
