import { styled } from '@mui/material/styles'
import { AppBar, Toolbar, Backdrop, Theme } from '@mui/material'
import { useState, useMemo } from 'react'
import { mediaQuery, useBoolean } from '../../functions/hooks'
import { MuiColorTypes } from '../../functions/types/Common'

const CustomSubAppbar = styled(AppBar)<{
  theme?: Theme
  colortypes: MuiColorTypes
}>`
  background-color: ${({ theme, colortypes }) => {
    return theme.palette[colortypes].light
  }};
  box-shadow: none;
  color: black;
  height: 600px;
  top: 160px;
  width: 25%;
  z-index: ${({ theme }) => theme.zIndex.drawer - 1};
  ${mediaQuery('sp')} {
    height: auto;
    top: 140px;
    width: 100%;
  }
`
const CustomSubToolbar = styled(Toolbar)`
  display: block;
  margin: 20px auto;
  min-height: auto;
  width: 95%;
`
const ContentWrapper = styled('div')`
  display: grid;
  gap: 20px;
  padding: 20px 0;
`
const FooterWrapper = styled('div')`
  display: block;
`
const Title = styled('div')`
  color: #4a4a4a;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`

const CustomBackdrop = styled(Backdrop)<{
  theme?: Theme
}>`
  z-index: ${({ theme }) => theme.zIndex.drawer - 2};
`

export interface BasicSearchFormProps {
  className?: string
  title: JSX.Element | string
  subtitle?: string
  contents?: JSX.Element
  footer?: JSX.Element
  color?: MuiColorTypes
}

export const BasicSearchForm: React.FC<BasicSearchFormProps> = ({
  title,
  subtitle,
  contents,
  footer,
  color = 'marudeli'
}) => {
  const { isResponsive } = useBoolean()
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => {
    if (isResponsive) {
      setIsOpen((prev) => !prev)
    }
  }

  const isVisible = useMemo(() => {
    if (!isResponsive) {
      return true
    }
    return isOpen
  }, [isOpen, isResponsive])

  return (
    <>
      <CustomSubAppbar position="sticky" colortypes={color}>
        <CustomSubToolbar>
          <Title onClick={handleClose}>{title}</Title>
          {isVisible && (
            <>
              <ContentWrapper>{contents}</ContentWrapper>
              <FooterWrapper>{footer}</FooterWrapper>
            </>
          )}
        </CustomSubToolbar>
      </CustomSubAppbar>
      <CustomBackdrop open={isOpen} onClick={handleClose} />
    </>
  )
}
