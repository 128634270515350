import { axios } from '../../../utilities/axiosClient'

export type SettingEmailComplete = {
  email: string
  email2: string
  action: string //back,confirmed
}

export type Email = {
  email: string
}

/**
 * ユーザーにメールアドレス変更メールを送付する
 * 以下のようなURLがメールには添付されている
 * http://mlog.development:3000/reset/email?token=oo8mqfOitjhGKPMWEsbXdQreLuHaJCFYXQG9ibGbLvwwktTOdIXhMZjXDhXw
 */

export const getSettingEmailSendEmail = (
  params: SettingEmailComplete
): Promise<Email> => axios.post('/api/mail_edit/email', params)
