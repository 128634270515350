import styled from '@emotion/styled'
import { useMemo } from 'react'
import { Image } from '.'
import { BaseTitle } from '../../functions/themes'
import { mediaQuery } from '../../functions/hooks'

const TitleWrapper = styled('div')`
  overflow: hidden;
  padding: 30px 0;
  position: relative;
  text-align: center;
  width: 100%;
  ${mediaQuery('sp')} {
    padding: 10px 0;
  }
`
const BackgroudTitle = styled(BaseTitle)`
  font-size: 70px;
  ${mediaQuery('sp')} {
    font-size: 50px;
  }
`
const SubImageWrapper = styled('div')`
  left: 50%;
  position: absolute;
  top: 20%;
  transform: translateX(-50%);
  ${mediaQuery('sp')} {
    top: 30%;
  }
`
const TitleText = styled(BaseTitle)`
  left: 50%;
  position: absolute;
  top: 60%;
  transform: translateX(-50%);
  width: inherit;
  ${mediaQuery('sp')} {
    position: relative;
    top: 0;
  }
`
export interface ContentTitleCenterProps {
  icon?: string
  title: string
  backgroudTitle: string
}

/**
 * トップページで使用している
 * ContentTitleCenterの目的はコンポーネントとして切り出してスコープを限定するため
 * 可読性を高めるため
 */
export const ContentTitleCenter: React.FC<ContentTitleCenterProps> = ({
  icon,
  title,
  backgroudTitle
}) => {
  const isQuestionnaire = useMemo(
    () => backgroudTitle === 'Questionnaire',
    [backgroudTitle]
  )
  return (
    <TitleWrapper>
      <BackgroudTitle className={isQuestionnaire ? '-blue' : '-white'}>
        {backgroudTitle}
      </BackgroudTitle>
      {icon && (
        <SubImageWrapper>
          <Image src={icon} />
        </SubImageWrapper>
      )}
      <TitleText>{title}</TitleText>
    </TitleWrapper>
  )
}
