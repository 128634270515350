import styled from '@emotion/styled'
import ErrorIcon from '@mui/icons-material/Error'
import { ButtonRound } from '.'
import { BaseButtonWrapper, BaseText } from '../../functions/themes'
import { useNavigate, useI18n } from '../../functions/hooks'
import { MuiColorTypes } from '../../functions/types/Common'

const Container = styled('div')`
  align-content: center;
  display: grid;
  gap: 20px;
  min-height: 300px;
  padding: 30px;
  text-align: center;
  width: 100%;
`
const CustomIcon = styled(ErrorIcon)`
  font-size: 70px;
`

type ErrorEmptyProps = {
  shouldHideButton?: boolean
  color?: MuiColorTypes
}

export const ErrorEmpty: React.FC<ErrorEmptyProps> = ({
  shouldHideButton = false,
  color = 'point'
}) => {
  const { pushToTop } = useNavigate()
  const { isJapanese } = useI18n()

  return (
    <Container>
      <div>
        <CustomIcon color={color} />
        <BaseText className="-bold">
          {isJapanese ? 'データがありません。' : 'No Data'}
        </BaseText>
      </div>
      {!shouldHideButton && (
        <BaseButtonWrapper>
          <ButtonRound onClick={pushToTop}>
            {isJapanese ? 'トップページへ戻る' : 'To the Top Page'}
          </ButtonRound>
        </BaseButtonWrapper>
      )}
    </Container>
  )
}
