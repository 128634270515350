import ReactPaginate from 'react-paginate'
import styled from '@emotion/styled'
import { useState, useEffect, useCallback } from 'react'
import { mediaQuery } from '../../functions/hooks'

const CustomPaginate = styled(ReactPaginate)`
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 20px 0;
  /* stylelint-disable */
  li a {
    cursor: pointer;
    padding: 10px 20px;
    border-right: gray 1px solid;
    border-top: gray 1px solid;
    border-bottom: gray 1px solid;
    ${mediaQuery('sp')} {
      padding: 12px;
    }
  }

  li.active a {
    background-color: #f4f4f4;
  }
  li.disabled a {
    background: #f9f9f9;
    color: gray;
    cursor: default;
  }

  .previous a {
    border-radius: 5px 0 0 5px;
    border: gray 1px solid;
  }
  .next a {
    border-radius: 0 5px 5px 0;
    border-right: gray 1px solid;
    border-top: gray 1px solid;
    border-bottom: gray 1px solid;
  }
`

type Selected = {
  selected: number
}

export const ITEMS_PER_PAGE = 12

export const Pagination = ({
  paginationItems,
  handleChangeItems
}: {
  paginationItems: any[]
  handleChangeItems: (f: any[]) => void
}) => {
  const [pageCount, setPageCount] = useState(0)
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(3)

  const handlePageRangeDisplayed = useCallback((all: number, now: number) => {
    const min = 0 //3を格納する
    const minOneBefore = 1 //2を格納する
    const minTwoBefore = 2 //1を格納する
    const maxTwoBefore = all - 3 //1を格納する
    const maxOneBefore = all - 2 //2を格納する
    const max = all - 1 //3を格納する

    // FIXME:リファクタしたい
    if (all > 5) {
      if (now === min) setPageRangeDisplayed((prev) => 3)
      if (now === minOneBefore) setPageRangeDisplayed((prev) => 2)
      if (now === minTwoBefore) setPageRangeDisplayed((prev) => 1)
      if (now === maxTwoBefore) setPageRangeDisplayed((prev) => 1)
      if (now === maxOneBefore) setPageRangeDisplayed((prev) => 2)
      if (now === max) setPageRangeDisplayed((prev) => 3)
    }
  }, [])

  const handlePageClick = ({ selected }: Selected) => {
    const itemOffset = (selected * ITEMS_PER_PAGE) % paginationItems.length
    const endOffset = itemOffset + ITEMS_PER_PAGE
    const newItems = paginationItems.slice(itemOffset, endOffset)
    handleChangeItems(newItems)

    const all = Math.ceil(paginationItems.length / ITEMS_PER_PAGE)
    const now = itemOffset / ITEMS_PER_PAGE
    handlePageRangeDisplayed(all, now)
    setPageCount(all)

    // 副作用が終わった後でスクロール位置をリセットさせる
    window.scrollTo(0, 0)
  }

  // 初回レンダリングのみ
  useEffect(() => {
    const endOffset = 0 + ITEMS_PER_PAGE
    const newItems = paginationItems.slice(0, endOffset)
    handleChangeItems(newItems)

    const all = Math.ceil(paginationItems.length / ITEMS_PER_PAGE)
    const now = 0 / ITEMS_PER_PAGE
    handlePageRangeDisplayed(all, now)
    setPageCount(all)
    /*eslint-disable*/
  }, [paginationItems])

  return (
    <CustomPaginate
      nextLabel=">"
      previousLabel="<"
      breakLabel="..."
      onPageChange={handlePageClick}
      marginPagesDisplayed={1} //先頭と末尾に表示するページの数。今回は2としたので1,2…今いるページの前後…後ろから2番目, 1番目 のように表示されます。
      pageRangeDisplayed={pageRangeDisplayed} //上記の「今いるページの前後」の番号をいくつ表示させるかを決めます。
      pageCount={pageCount}
      pageLinkClassName="page-link"
      previousClassName="previous"
      previousLinkClassName="previous-link"
      nextClassName="next"
      nextLinkClassName="next-link"
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination"
      activeClassName="active"
      renderOnZeroPageCount={undefined}
    />
  )
}
