import { useQueryClient, useMutation } from 'react-query'
import { useErrorHandler } from 'react-error-boundary'
import { axios } from '../../utilities/axiosClient'
import { EventDetail, Address } from '../../types/Event'

/**
 * もともと詳細画面にリダイレクトしていたということもあり、
 * イベント詳細のデータが戻り値となっているが使わなくて良さそう
 */
export type EventApplyCompleteRequest = {
  id: number
  action: string // 値はbackとconfirmedの2つのみを許容
  no1_selection_result: string
  no1_contents_result: string
  no2_selection_result: string
  no2_contents_result: string
  apply_present_id?: string // プレゼントID
} & Address

export const useEventApplyComplete = () => {
  const queryClient = useQueryClient()
  const handleError = useErrorHandler()

  const eventApplyCompleteMutation = useMutation(
    (params: EventApplyCompleteRequest): Promise<EventDetail> =>
      axios.post(`/api/event/${params.id}/apply/complete`, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getEventDetail')
        queryClient.invalidateQueries('getEventApply')
      },
      onError: (error) => {
        handleError(error)
      }
    }
  )

  return { eventApplyCompleteMutation }
}
