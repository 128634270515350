import * as yup from 'yup'
import { getCardType, checkCardNumber, checkDate } from '.'

const CARD_TYPE = [
  'american-express',
  'diners-club',
  'jcb',
  'mastercard',
  'visa'
]

/**
 * arrow functionを使うとthisを上書きされるので使わない
 */
function maxLengthValidation(this: any, label: string, max: number) {
  return this.test('maxLength', 'maxLength error', (value: unknown) => {
    if (typeof value !== 'string') return true
    const isValid = value.length <= max
    return isValid
  })
}

yup.addMethod(yup.string, 'maxLength', maxLengthValidation)

/**
 * 半角数字のみ許容する
 */
function onlyNumberValidation(this: any) {
  return this.matches(/^[0-9]+$/, {
    message: '半角数字で入力してください。'
  })
}

yup.addMethod(yup.string, 'onlyNumber', onlyNumberValidation)

/**
 * フォーム未入力の際に発火するバリデーション
 * 半角・全角スペースのみの場合にもバリデーションする
 */
function defaultTitleValidation(this: any, errorText: string) {
  return this.trim().required(errorText)
}

yup.addMethod(yup.string, 'customRequired', defaultTitleValidation)

/**
 * クレジットカードに関係するバリデーション
 */
function creditCardNumberValidation(this: any, bool: boolean) {
  return this.required('カード番号を入力してください。')
    .test(
      'cardType',
      `ご利用できないクレジットカードブランドです。`,
      (value: string) => {
        const type = getCardType(value)
        if (type === '') return true
        return CARD_TYPE.includes(type)
      }
    )
    .test(
      'luhnAlgorithm',
      '有効なクレジットカード番号を入力してください。',
      (value: string) => {
        return checkCardNumber(value)
      }
    )
}

yup.addMethod(yup.string, 'creditCardNumber', creditCardNumberValidation)

function creditCardExpireValidation(this: any, bool: boolean) {
  return this.required('有効期限を入力してください。').test(
    'luhnAlgorithm',
    '有効な日付を入力してください。',
    (value: string) => {
      return checkDate(value)
    }
  )
}

yup.addMethod(yup.string, 'creditCardExpire', creditCardExpireValidation)

export const validator = yup
