import { MenuItem, TextField, CircularProgress } from '@mui/material'
import styled from '@emotion/styled'
import { Controller, Control } from 'react-hook-form'
import { BasicLabel } from '.'
import { OptionProps } from '../../functions/types/Common'
import { useI18n } from '../../functions/hooks'
import { MypageProfileRequest } from '../../functions/apis/mypage/mypageEdit'

const Wrapper = styled('div')`
  display: grid;
  gap: 5px;
`
const MainText = styled('p')`
  font-size: 15px;
  font-weight: bold;
`
const SubText = styled('p')`
  font-size: 12px;
`
const LabelWrapper = styled('div')`
  align-items: center;
  display: flex;
  gap: 5px;
`
const Label = styled('label')`
  font-size: 15px;
  font-weight: 600;
`
const CustomTextField = styled(TextField)`
  width: 100%;
  .MuiOutlinedInput-root {
    background: white;
  }
`

const Placeholder = styled('p')<{ color?: string }>`
  color: ${({ color }) => color || `gray`};
`

export type SelectFormProps = {
  // NOTE:react-hook-form
  error?: boolean
  helperText?: string
  control?: Control<any>
  // NOTE:必須項目
  options: OptionProps[] | null
  name: any
  // NOTE:追加項目
  id?: string
  size?: 'small' | 'medium'
  labelId?: string
  label?: string
  placeholder?: string
  disabled?: boolean
  mainText?: string
  subTexts?: string[] //複数入ることを想定
  isRequired?: boolean
  placeholderColor?: string
}

export const InputSelectControlled: React.FC<SelectFormProps> = ({
  error = false,
  helperText = '',
  id,
  name,
  size,
  labelId = 'select',
  label,
  options,
  placeholder,
  disabled = false,
  mainText = '',
  subTexts = [],
  isRequired = true,
  placeholderColor,
  control
}) => {
  const { isJapanese } = useI18n()
  return (
    <Wrapper>
      {label ? (
        <LabelWrapper>
          <Label id={labelId}>{label}</Label>
          <BasicLabel isRequired={isRequired}>
            {isJapanese ? '必須' : 'required'}
          </BasicLabel>
        </LabelWrapper>
      ) : null}
      {mainText ? <MainText>{mainText}</MainText> : null}
      {subTexts.length !== 0
        ? subTexts.map((subText) => <SubText key={subText}>{subText}</SubText>)
        : null}

      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <CustomTextField
            {...field}
            id={id}
            size={size}
            select
            disabled={disabled}
            helperText={helperText}
            error={error}
            SelectProps={{
              renderValue: () => {
                if (field.value === '') {
                  return (
                    <Placeholder color={placeholderColor}>
                      {placeholder}
                    </Placeholder>
                  )
                }
                if (options && options.length !== 0) {
                  return (
                    options.find((option) => option.value === field.value)
                      ?.label || ''
                  )
                }
                return <CircularProgress size={20} />
              }
            }}
          >
            {options &&
              options.length !== 0 &&
              options.map((option) => (
                <MenuItem
                  key={`${option.label}-${option.value}`}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
          </CustomTextField>
        )}
      />
    </Wrapper>
  )
}
