import { axios } from '../../utilities/axiosClient'

export type MachiPassWithUpdateSite = {
  alignmentFlg: boolean
  // 連携が成功した場合の戻り値
  // 連携完了画面へ遷移
  access_token?: string
  token_type?: string
  expires_in?: string
  // 連携が失敗した時の戻り値
  // 連携フォーム入力画面に留まる
  message?: string
}

export type MachiPassWithUpdateSiteProps = {
  email: string
  password: string
  sub?: string
  machipass_expire?: string
  machipass_access_token?: string
  machipass_refresh_token?: string
}

/**
 * MachiPassでログイン後、updateMarunouchi内に同じメールアドレスを持っている、かつMachiPass未連携のユーザーがいる場合に、
 * それらのユーザーを紐付け、MachiPassのユーザー情報でDBを上書きする
 * 処理に成功すれば連携完了画面(getMachiPassLinkCompleteFetch)に、
 * 失敗で連携用ログイン画面()に遷移させる
 */

export const getMachiPassWithUpdateSite = (
  params: MachiPassWithUpdateSiteProps
): Promise<MachiPassWithUpdateSite> => axios.post('/api/machi/link', params)
