import styled from '@emotion/styled'
import ModalUnstyled from '@mui/base/ModalUnstyled'
import { BaseText, DottedTwoLine } from '../../functions/themes'
import { mediaQuery } from '../../functions/hooks'
import { ButtonIconClose } from '.'

const StyledModal = styled(ModalUnstyled)`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1300;
`

const Backdrop = styled('div')`
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  z-index: -1;
`

const ModalInner = styled('div')<{ size: string }>`
  background-color: white;
  border-radius: 10px;
  padding: 10px 0 50px 0;
  position: relative;
  width: ${({ size }) => {
    if (size === 'small') return '600px'
    if (size === 'medium') return '800px'
    if (size === 'large') return '1200px'
    return '600px'
  }};
  &.-bg-transparent {
    background-color: transparent;
  }
  ${mediaQuery('sp')} {
    margin: auto;
    width: 90%;
  }
  &.-big-category-style {
    ${mediaQuery('sp')} {
      height: calc(100vh - 10vh);
    }
  }
  &.-small-category-style {
    ${mediaQuery('sp')} {
      height: calc(100vh - 10vh);
    }
  }
`
const Close = styled(ButtonIconClose)`
  display: flex;
  justify-content: end;
  margin: auto;
  width: calc(100% - 20px);
`
const Headline = styled(BaseText)`
  font-size: 24px;
  padding: 0 30px;
  &.-pt40 {
    padding-top: 40px;
  }
  ${mediaQuery('sp')} {
    font-size: 18px;
  }
`
const CustomText = styled(DottedTwoLine)`
  padding: 0 30px;
`
const ContentWrapper = styled('div')`
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: 30px;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  /* border: 1px solid grey; */
  &.-block {
    display: block;
  }
  &.-no-max-height {
    max-height: none;
  }
  &.-big-category-style {
    margin: 30px;
    max-height: initial;
    ${mediaQuery('sp')} {
      display: flex;
      height: calc(100vh - 27vh);
      margin: 30px;
      max-height: initial;
    }
  }
  &.-small-category-style {
    height: calc(100vh - 40vh);
    margin: 30px 60px;
    max-height: initial;
    ${mediaQuery('sp')} {
      height: calc(100vh - 40vh);
      margin: 10px 30px;
      max-height: initial;
    }
  }
`

const FooterWrapper = styled('div')`
  display: flex;
  gap: 30px;
  justify-content: center;
  &.-block {
    display: block;
  }
  &.-voucher-category-style {
    bottom: 50px;
    left: 0;
    position: absolute;
    right: 0;
  }
`

export interface ModalProps {
  className?: string
  title: string | JSX.Element
  subtitle?: string
  contents?: JSX.Element
  footer?: JSX.Element
  open: boolean
  size?: 'small' | 'medium' | 'large'
  handleOpen: () => void
  handleCloseWithIcon?: () => void
  isDisabledFlex?: boolean
  isFooterBlock?: boolean
  isBigCategoryModal?: boolean
  isSmallCategoryModal?: boolean
  isVoucherCategory?: boolean
}

export const BasicModal: React.FC<ModalProps> = ({
  className,
  open = false,
  title,
  subtitle,
  contents,
  footer,
  size = 'small',
  handleOpen,
  isDisabledFlex = false,
  isFooterBlock = false,
  handleCloseWithIcon, // これがない場合はpxを40足す必要がある
  isBigCategoryModal = false,
  isSmallCategoryModal = false,
  isVoucherCategory = false
}) => {
  return (
    <StyledModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleOpen}
      components={{ Backdrop }}
      closeAfterTransition
    >
      <ModalInner
        size={size}
        className={`className ${
          isBigCategoryModal ? '-big-category-style' : ''
        } ${isSmallCategoryModal ? '-small-category-style' : ''}`}
      >
        {handleCloseWithIcon ? (
          <>
            <Close onClick={handleCloseWithIcon} />
            <Headline className="-bold -center">{title}</Headline>
          </>
        ) : (
          <Headline className="-bold -pt40 -center">{title}</Headline>
        )}
        {subtitle && <CustomText className="-center">{subtitle}</CustomText>}
        <ContentWrapper
          className={`${isDisabledFlex ? '-block' : ''} ${
            isBigCategoryModal ? '-big-category-style' : ''
          } ${isSmallCategoryModal ? '-small-category-style' : ''}`}
        >
          {contents}
        </ContentWrapper>
        <FooterWrapper
          className={`${isFooterBlock ? '-block' : ''} ${
            isVoucherCategory ? '-voucher-category-style' : ''
          }`}
        >
          {footer}
        </FooterWrapper>
      </ModalInner>
    </StyledModal>
  )
}
