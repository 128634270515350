import styled from '@emotion/styled'
import { Theme } from '@mui/system'
import { memo } from 'react'
import { Button } from '.'
import { MuiColorTypes } from '../../functions/types/Common'

const FilterContainer = styled('div')<{ color: string }>`
  border-bottom: ${({ color }) => `1px solid ${color}`};
  width: 100%;
`
const FilterNav = styled('nav')`
  display: block;
`

const CustomButton = styled(Button)<{ theme?: Theme; color: MuiColorTypes }>`
  margin: 10px;
  &.active {
    background-color: ${({ theme, color }) => {
      return theme.palette[color].main
    }};
    color: white;
    transition: 0.1s ease;
    transition-delay: 0.1s;
  }
`

interface FilterButtonProps {
  className?: string
  currentFilter: string
  changeFilter: (newFilter: string) => void
  items: Array<string>
  color?: MuiColorTypes
  size?: 'small' | 'medium' | 'large'
}

/**
 * ベースはFilterTabの実装と同じ
 * FilterTabとはレイアウトだけ異なる
 * MUIのエフェクトを使うため実装
 * 逆に色を限定する目的もあるならFilterButtonをメインに寄せていくのもあり
 */
export const FilterButton: React.FC<FilterButtonProps> = memo(
  ({
    className,
    currentFilter,
    changeFilter,
    items,
    color = 'marudeli',
    size = 'small'
  }) => {
    return (
      <FilterContainer color={color}>
        <FilterNav>
          {items.map((item) => (
            <CustomButton
              key={item}
              onClick={() => changeFilter(item)}
              className={
                currentFilter === item ? `active ${className}` : className
              }
              color={color}
              size={size}
              variant="outlined"
            >
              {item}
            </CustomButton>
          ))}
        </FilterNav>
      </FilterContainer>
    )
  }
)
