import { useEffect, useState } from 'react'

type Point = {
  x: number
  y: number
}
type Pointer = {
  top: number
  left: number
}
type Circle = {
  top: number
  left: number
  width: number
}
type KeyType = 0 | 1 | 2 | 3
type ErrorMessage = { [key in KeyType]: string }

export const useGeolocation = () => {
  const [pointer, setPointer] = useState<Pointer | null>(null)
  const [circle, setCircle] = useState<Circle | null>(null)
  // 交点取得
  const getIntersectionLineSegments = (
    point1: Point,
    point2: Point,
    point3: Point,
    point4: Point
  ) => {
    const x0 = point1.x
    const y0 = point1.y

    const x1 = point2.x
    const y1 = point2.y

    const x2 = point3.x
    const y2 = point3.y

    const x3 = point4.x
    const y3 = point4.y

    const culc0 = (y1 - y0) / (x1 - x0)
    const culc1 = (y3 - y2) / (x3 - x2)

    const x = (culc0 * x0 - y0 - culc1 * x2 + y2) / (culc0 - culc1)
    const y = ((y1 - y0) / (x1 - x0)) * (x - x0) + y0

    if (Math.abs(culc0) === Math.abs(culc1)) return {} as Point

    if (
      x > Math.max(x0, x1) ||
      x > Math.max(x2, x3) ||
      y > Math.max(y0, y1) ||
      y > Math.max(y2, y3) ||
      x < Math.min(x0, x1) ||
      x < Math.min(x2, x3) ||
      x < Math.min(x0, x1) ||
      y < Math.min(y2, y3)
    )
      return {} as Point

    return { x, y } as Point
  }

  //地図上の位置計算
  const setMapLocation = (
    latitude: number,
    longitude: number,
    map_w: number,
    map_h: number
  ) => {
    // 地図画像の四隅の緯度・軽度
    const p_1 = { y: 35.672884, x: 139.754845 } // 左上
    const p_2 = { y: 35.690647, x: 139.760546 } // 右上
    const p_3 = { y: 35.68839, x: 139.772248 } // 右下
    const p_4 = { y: 35.670498, x: 139.766442 } // 左下

    /**
     * latitude:経度＞「横線」
     * lat_1（小）→lat_4（大）で数値が増えていく
     * 経度なので下部では数値が小さく、上部では数値が大きくなる
     */
    const lat_1 = p_4.y // 「左」下
    const lat_2 = p_1.y // 「左」上
    const lat_3 = p_3.y // 「右」下
    const lat_4 = p_2.y // 「右」上

    /**
     * longitude:緯度＞「縦線」
     * lon_1（小）→lon_4（大）で数値が増えていく
     * 緯度なので左側では数値が小さくなり、右側では数値が大きくなる
     */
    const lon_1 = p_1.x // 左「上」
    const lon_2 = p_2.x // 右「上」
    const lon_3 = p_4.x // 左「下」
    const lon_4 = p_3.x // 右「下」

    //水平
    const hol_1 = { y: 0, x: 0 }
    const hol_2 = { y: 0, x: 0 }

    //垂直
    const ver_1 = { y: 0, x: 0 }
    const ver_2 = { y: 0, x: 0 }

    let point = {} as Point

    if (
      lat_1 <= latitude &&
      latitude <= lat_4 &&
      lon_1 <= longitude &&
      longitude <= lon_4
    ) {
      /**
       * 上記のif文を突破する場合次の緯度1,2のロジックに引っかかりhol_1,hol_2を必ず埋める
       * =========================================
       * 条件分岐a,c（下部）
       * 条件分岐b,c（真ん中）
       * 条件分岐b,d（上部）
       * =========================================
       */
      // 緯度1
      if (lat_1 <= latitude && latitude <= lat_2) {
        // 条件分岐a
        hol_1.x = 0
        hol_1.y = Math.round(((lat_2 - latitude) / (lat_2 - lat_1)) * map_h) // マップ縮尺の高さ
      } else if (lat_2 < latitude && latitude <= lat_4) {
        // 条件分岐b
        hol_1.x = Math.round(((latitude - lat_2) / (lat_4 - lat_2)) * map_w) // マップ縮尺の横幅
        hol_1.y = 0
      }
      // 緯度2
      if (lat_1 <= latitude && latitude <= lat_3) {
        // 条件分岐c
        hol_2.x = Math.round(((latitude - lat_1) / (lat_3 - lat_1)) * map_w)
        hol_2.y = map_h
      } else if (lat_3 < latitude && latitude <= lat_4) {
        // 条件分岐d
        hol_2.x = map_w
        hol_2.y = Math.round(((lat_4 - latitude) / (lat_4 - lat_3)) * map_h)
      }

      /**
       * 上記のif文を突破する場合次の経度1,2のロジックに引っかかりver_1,ver_2を必ず埋める
       * =========================================
       * 条件分岐a`,c`（左）
       * 条件分岐b`,c`（真ん中）
       * 条件分岐b`,d`（右）
       * =========================================
       */
      // 経度1
      if (lon_1 <= longitude && longitude <= lon_2) {
        // 条件分岐a`
        ver_1.x = Math.round(((longitude - lon_1) / (lon_2 - lon_1)) * map_w)
        ver_1.y = 0
      } else if (lon_2 < longitude && longitude <= lon_4) {
        // 条件分岐b`
        ver_1.x = map_w
        ver_1.y = Math.round(((longitude - lon_2) / (lon_4 - lon_2)) * map_h)
      }

      // 経度2
      if (lon_1 <= longitude && longitude <= lon_3) {
        // 条件分岐c`
        ver_2.x = 0
        ver_2.y = Math.round(((longitude - lon_1) / (lon_3 - lon_1)) * map_h)
      } else if (lon_3 < longitude && longitude <= lon_4) {
        // 条件分岐d`
        ver_2.x = Math.round(((longitude - lon_3) / (lon_4 - lon_3)) * map_w)
        ver_2.y = map_h
      }

      point = getIntersectionLineSegments(hol_1, hol_2, ver_1, ver_2)
    }

    return point
  }

  /**
   * latitude:経度＞縦線
   * longitude:緯度＞横線
   */
  const initMap = (position: GeolocationPosition) => {
    let point = {} as Point
    let latitude = 0
    let longitude = 0

    const map_w = 2387
    const map_h = 1114
    // 1248
    const pnt_w = 46 // 恐らくpoint_width
    const pnt_h = 55 // 恐らくpoint_height

    if (position) {
      latitude = position.coords.latitude
      longitude = position.coords.longitude

      // テスト用
      // latitude = 35.68116498123001
      // longitude = 139.7637906374446
      point = setMapLocation(latitude, longitude, map_w, map_h) // 地図上の位置計算

      if (!point) {
        // ex)丸ビル
        // latitude = 35.68116498123001
        // longitude = 139.7637906374446

        // ex)パレスホテル東京
        // latitude = 35.68461433499525
        // longitude = 139.7612531265328

        // ex)京橋駅
        // latitude = 35.67671682596627
        // longitude = 139.7700446269573

        // ex)東京駅
        latitude = 35.68127623500931
        longitude = 139.7657935903491
        point = setMapLocation(latitude, longitude, map_w, map_h)
      }

      // ポインター設置
      const pointerTop = ((point.y - pnt_h) / map_h) * 100
      const pointerLeft = ((point.x - pnt_w / 2) / map_w) * 100

      const pointerParams = {
        top: pointerTop,
        left: pointerLeft
      }

      setPointer(pointerParams)

      // サークル設置
      const distance = 1503 // 地図の横幅の距離(m)
      const diameter = (distance / map_w) * 200 // 直径200m

      const circleTop = ((point.y - diameter / 2) / map_h) * 100
      const circleLeft = ((point.x - diameter / 2) / map_w) * 100
      const circleWidth = (diameter / map_w) * 100

      const circleParams = {
        top: circleTop,
        left: circleLeft,
        width: circleWidth
      }

      setCircle(circleParams)
    }
  }

  const onSuccess = (position: GeolocationPosition) => {
    initMap(position)
  }

  const onError = (error: GeolocationPositionError) => {
    const errorMessage: ErrorMessage = {
      0: '原因不明のエラーが発生しました。',
      1: '位置情報の取得が許可されませんでした。',
      2: '電波状況などで位置情報が取得できませんでした。',
      3: '位置情報の取得に時間がかかり過ぎてタイムアウトしました。'
    }

    alert(errorMessage[error.code as KeyType])
  }

  const params = {
    enableHighAccuracy: false,
    timeout: 8000,
    maximumAge: 5000
  }

  // 位置情報取得
  const setGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError, params)
    } else {
      alert('あなたの端末では、現在位置を取得することができません。')
    }
  }

  useEffect(() => {
    setGeolocation()
    /* eslint-disable */
  }, [])

  return { pointer, circle }
}
