import { lazyRetry } from '../../functions/utilities'

const Login = lazyRetry(
  () => import(/* webpackChunkName: "en-login-page" */ '../../pages/Login')
)
const Complete = lazyRetry(
  () =>
    import(/* webpackChunkName: "en-complete-page" */ '../../pages/Complete')
)
const SettingResetPassword = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "en-setting-reset-password-page" */ '../../pages/SettingResetPassword'
    )
)

export const publicRoutes = [
  {
    path: '/en/setting/reset/password',
    element: <SettingResetPassword />
  },
  {
    path: '/en/setting/reset/password/complete',
    element: <Complete />
  },
  {
    path: '*',
    element: <Login />
  }
]
