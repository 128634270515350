import { lazyRetry } from '../../functions/utilities'

const Top = lazyRetry(
  () => import(/* webpackChunkName: "top-page" */ '../../pages/Top')
)
const Landing = lazyRetry(
  () => import(/* webpackChunkName: "landing-page" */ '../../pages/Landing')
)
const Guidance = lazyRetry(
  () => import(/* webpackChunkName: "guidance-page" */ '../../pages/Guidance')
)
const Login = lazyRetry(
  () => import(/* webpackChunkName: "login-page" */ '../../pages/Login')
)

const SettingProfile = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "setting-profile-page" */ '../../pages/SettingProfile'
    )
)
const MachipassLogin = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "machipass-login-page" */ '../../pages/MachiPassLogin'
    )
)
const Register = lazyRetry(
  () => import(/* webpackChunkName: "register-page" */ '../../pages/Register')
)
const Question = lazyRetry(
  () => import(/* webpackChunkName: "question-page" */ '../../pages/Question')
)
const Contact = lazyRetry(
  () => import(/* webpackChunkName: "contact-page" */ '../../pages/Contact')
)
const PrivacyPolicy = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "privacy-policy-page" */ '../../pages/PrivacyPolicy'
    )
)
const Terms = lazyRetry(
  () => import(/* webpackChunkName: "terms-page" */ '../../pages/Terms')
)
const Error = lazyRetry(
  () => import(/* webpackChunkName: "error-page" */ '../../pages/Error')
)
const Complete = lazyRetry(
  () => import(/* webpackChunkName: "complete-page" */ '../../pages/Complete')
)
const FanWeek = lazyRetry(
  () => import(/* webpackChunkName: "fanweek-page" */ '../../pages/FanWeek')
)

export const commonRoutes = [
  {
    path: '/',
    element: <Top />
  },
  {
    path: '/lp',
    element: <Landing />
  },
  {
    path: '/tutorial',
    element: <Guidance />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/machi/link/login',
    element: <MachipassLogin />
  },
  {
    path: '/machi/link/login/complete',
    element: <Complete />
  },
  {
    path: '/additional/input',
    element: <SettingProfile />
  },
  {
    path: '/machi/link/complete',
    element: <Complete />
  },
  {
    path: '/register',
    element: <Register />
  },
  {
    path: '/resign/complete',
    element: <Complete />
  },
  {
    path: '/question',
    element: <Question />
  },
  {
    path: '/contact',
    element: <Contact />
  },
  {
    path: '/privacy',
    element: <PrivacyPolicy />
  },
  {
    path: '/terms',
    element: <Terms />
  },
  {
    path: '/error',
    element: <Error />
  },
  {
    path: '/fanweek2023',
    element: <FanWeek />
  }
]
