import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  /**
   * ======event・jounal・voucher======
   * main:検索フォームのボタンのcolor
   * light:検索フォームの背景のcolor
   * 普通にグローバルな型定義に追加で良いかもしれない
   * ======marudeli・questionnaire======
   * main:
   */
  palette: {
    base: {
      main: '#F5F5F5', //主にmarudeli/procedureで使用している
      background: '#ffffff'
    },
    auth: {
      main: '',
      background: '#f8f2ed'
    },
    event: {
      main: '#8DBF6A',
      light: '#D8E9CE',
      background: '#f1f9ec'
    },
    journal: {
      main: '#9696D0',
      light: '#DFDFF3',
      background: '#f0f0fa'
    },
    voucher: {
      main: '#E1AA80',
      light: '#F5E9DF',
      background: '#f8f2ed'
    },
    marudeli: {
      main: '#CC3C4D',
      light: '#f2f2f2', // NOTE:白系の色なので注意
      background: '#fbf5f4'
    },
    questionnaire: {
      main: '#7CC3D2',
      light: '',
      background: '#ffffff'
    },
    point: {
      main: '#F08E35',
      light: '',
      background: ''
    }
  },
  // NOTE:https://mui.com/material-ui/api/button/#css
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none' // 英語のuppercaseを打ち消す
        },
        outlined: {
          background: 'white'
        },
        sizeMedium: {
          // minWidth: 130,
          height: 45,
          paddingLeft: 20,
          paddingRight: 20
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // fieldset: {
          //   borderColor: "blue",
          // },
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        input: {
          // '&:-webkit-autofill': {
          //   WebkitBoxShadow: '0 0 0 100px #f4f4f4 inset'
          // }
        },
        underline: {
          /* default */
          // '&:before': {
          //   border: 'none'
          // },
          /* hover */
          // '&&:hover:before': {
          //   borderBottom: 'none'
          // },
          /* focused */
          // '&:after': {
          //   borderBottom: 'none'
          // }
        }
      }
    }
  }
})
