import { TextField, MenuItem, Paper } from '@mui/material'
import styled from '@emotion/styled'
import { UseFormRegisterReturn } from 'react-hook-form'
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled'
import { useEffect, useMemo } from 'react'
import { BasicLabel } from '.'
import { OptionProps } from '../../functions/types/Common'
import { useI18n } from '../../functions/hooks'

const Wrapper = styled('div')`
  display: grid;
  gap: 5px;
`
const MainText = styled('p')`
  font-size: 15px;
  font-weight: bold;
`
const SubText = styled('p')`
  font-size: 12px;
`
const LabelWrapper = styled('div')`
  align-items: center;
  display: flex;
  gap: 5px;
`
const Label = styled('label')`
  font-size: 15px;
  font-weight: 600;
`
const CustomTextField = styled(TextField)`
  width: 100%;
  .MuiOutlinedInput-root {
    background: white;
  }
`

export type InputAutocompleteProps = {
  onChange?: (bool: boolean) => void
  // NOTE:エラーハンドリング
  register?: UseFormRegisterReturn
  error?: boolean
  helperText?: string
  // NOTE:必須項目
  options: OptionProps[]
  // NOTE:追加項目
  id?: string
  label?: string
  localValue: string
  placeholder?: string
  disabled?: boolean
  isLoading?: boolean
  mainText?: string
  subTexts?: string[] //複数入ることを想定
  isRequired?: boolean
  readOnly?: boolean
}

export const InputAutocomplete: React.FC<InputAutocompleteProps> = ({
  onChange,
  register,
  error = false,
  helperText = '',
  id,
  label,
  localValue,
  options,
  placeholder,
  disabled = false,
  isLoading = false,
  mainText = '',
  subTexts = [],
  isRequired = true,
  readOnly = false
}) => {
  const { isJapanese } = useI18n()
  const {
    value,
    inputValue,
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions
  } = useAutocomplete({
    options,
    getOptionLabel: (option) => option.label
    // selectOnFocus: false
  })
  /**
   * 強制的にonMouseDownのイベントハンドラを殺す
   */
  const newProps = getInputProps()
  delete newProps.onMouseDown

  /**
   * かなりドメイン絞られるが後ほどリストをクリックしてvalueを選択した際の
   * 処理をリファクタする予定
   */
  const isOther = useMemo(() => {
    return ['その他', 'Ｏｔｈｅｒ'].includes(value?.label || '')
  }, [value])

  useEffect(() => {
    if (onChange) onChange(isOther)
  }, [isOther, onChange])

  return (
    <Wrapper>
      {label ? (
        <LabelWrapper>
          <Label>{label}</Label>
          <BasicLabel isRequired={isRequired}>
            {isJapanese ? '必須' : 'required'}
          </BasicLabel>
        </LabelWrapper>
      ) : null}
      {mainText ? <MainText>{mainText}</MainText> : null}
      {subTexts.length !== 0
        ? subTexts.map((subText) => <SubText key={subText}>{subText}</SubText>)
        : null}
      <CustomTextField
        disabled={disabled}
        placeholder={placeholder}
        inputProps={{
          ...newProps,
          value: newProps.value || localValue,
          readOnly
        }}
        helperText={helperText}
        error={error}
        {...register}
      />
      {/* 二文字目からサジェストを実施する */}
      {newProps.value && groupedOptions.length > 0 ? (
        <Paper {...getListboxProps()} component="ul">
          {(groupedOptions as typeof options).map((option, index) => {
            return (
              <MenuItem {...getOptionProps({ option, index })}>
                {option.label}
              </MenuItem>
            )
          })}
        </Paper>
      ) : null}
    </Wrapper>
  )
}
