import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { Controller, Control, UseFormRegisterReturn } from 'react-hook-form'
import { styled, Theme } from '@mui/material/styles'
import MuiCircleIcon from '@mui/icons-material/Circle'
import { ErrorText, CircleIcon, CircleIconBlank, BasicLabel } from '.'
import { OptionProps, MuiColorTypes } from '../../functions/types/Common'
import { useI18n } from '../../functions/hooks'

const Wrapper = styled(FormControl)`
  display: grid;
  gap: 5px;
`
const SubText = styled('p')`
  font-size: 12px;
`
const MainText = styled('p')`
  font-size: 15px;
  font-weight: bold;
`
const LabelWrapper = styled('div')`
  align-items: center;
  display: flex;
  gap: 5px;
`
const Label = styled('label')`
  font-size: 15px;
  font-weight: 600;
`
const CustomRadio = styled(Radio)<{ theme?: Theme; colortype?: MuiColorTypes }>`
  color: ${({ theme, colortype }) => {
    return colortype ? theme.palette[colortype].main : ''
  }};
  min-height: 38px;
  min-width: 38px;
  &.Mui-checked {
    color: ${({ theme, colortype }) => {
      return colortype ? theme.palette[colortype].main : ''
    }};
  }
`

export type InputRadioGroupProps = {
  id?: string
  label?: string
  type?: 'row' | 'twoRow' | 'column'
  colorTypes?: MuiColorTypes
  size?: 'small' | 'medium'
  options: OptionProps[]
  value?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void // RFCを使わない場合に必要
  mainText?: string
  subTexts?: string[] // 複数入ることを想定し配列
  isRequired?: boolean
  // エラーハンドリング
  error?: boolean
  helperText?: string
  // NOTE:custom icon
  icon?: JSX.Element
  checkedIcon?: JSX.Element
  control?: Control<any>
  name: any
  register?: UseFormRegisterReturn
}

export const InputRadioGroupControlled: React.FC<InputRadioGroupProps> = ({
  id,
  label,
  type = 'column',
  colorTypes = 'point',
  size = 'small',
  options,
  mainText,
  subTexts = [],
  isRequired = true,
  error,
  helperText,
  icon = (
    <CircleIcon
      content={<MuiCircleIcon color="base" sx={{ fontSize: 14 }} />}
      size={size}
    />
  ),
  checkedIcon = (
    <CircleIconBlank
      content={<MuiCircleIcon color={colorTypes} sx={{ fontSize: 14 }} />}
      size={size}
    />
  ),
  control,
  name,
  register
}) => {
  const { isJapanese } = useI18n()
  return (
    <Wrapper>
      {label ? (
        <LabelWrapper>
          <Label htmlFor={id}>{label}</Label>
          <BasicLabel isRequired={isRequired}>
            {isJapanese ? '必須' : 'required'}
          </BasicLabel>
        </LabelWrapper>
      ) : null}
      {mainText ? <MainText>{mainText}</MainText> : null}
      {subTexts.length !== 0
        ? subTexts.map((subText) => <SubText key={subText}>{subText}</SubText>)
        : null}
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <RadioGroup
              row={!(type === 'row')}
              aria-labelledby={id}
              name="radio-buttons-group"
              sx={{ justifyContent: type === 'twoRow' ? 'space-around' : '' }}
              defaultValue={field.value}
            >
              {options.map((radio) => {
                return (
                  <FormControlLabel
                    key={radio.value}
                    label={radio.label}
                    control={
                      <CustomRadio
                        size={size}
                        value={radio.value}
                        icon={icon}
                        {...register}
                        checkedIcon={checkedIcon}
                      />
                    }
                  />
                )
              })}
            </RadioGroup>
          )
        }}
      />
      <ErrorText error={error} helperText={helperText} />
    </Wrapper>
  )
}
