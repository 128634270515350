import { axios } from '../../utilities/axiosClient'
import { Events } from '../../types/Event'

type EventTypeAndStatus = {
  count?: number
  type?: number
  status?: number
}

export const getEvent = (params?: EventTypeAndStatus): Promise<Events> =>
  axios.post('/api/event', params)
