import { axios } from '../../utilities/axiosClient'

export type Mypage = {
  new_flag_date: string // 新着ジャーナル確認用日時(使わない気がする)
  bldg_name?: string
  bldg_id?: number
  holiday: Record<string, Record<string, string>>
  token_api_key: string
  profile: Profile
  login_uri: string
  signup_uri: string
}

export type Profile = {
  name: string
  name_kana: string
  gender_div: string // 男性
  birth_date: string // 1999-12-31T15:00:00.000000Z
  email: string
  building_name: string
  workplace: string
  position: string // 役員
  industry_type: string // 営業
  newsletter: string // 購読
  machipass_status: string // 未連携
}

export type Genres = {
  id: number
  name: string
}

export type Building = {
  address: string //'東京都千代田区大手町一丁目６番１号'
  address_label: string //'大手町一丁目'
  building_name_eng: string //'Otemachi Building'
  building_name_jpn: string //'大手町ビル'
  id: number //11
  latitude: number //35.686476
  longitude: number //139.76409
  outside_management_id: number // 11506
  small_area_id: number // 1
  workplace_div: number // 1
  zipcode: string // '1000004'
}

/**
 * TODO:OrderData,Links,Cards,ImageUrlの型定義
 */
export const getMypage = (): Promise<Mypage> => axios.get('/api/mypage')
