import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import QUESTION_JPN from '../constants/question/japanese/question.json'
import QUESTION_MARUDELI_JPN from '../constants/question/japanese/questionMarudeli.json'
import QUESTION_ENG from '../constants/question/english/question.json'
import CONTACT_JPN from '../constants/contact/japanese/contact.json'
import CONTACT_ENG from '../constants/contact/english/contact.json'
import TERMS_JPN from '../constants/terms/japanese/terms.json'
import TERMS_ENG from '../constants/terms/english/terms.json'
import PRYVACY_POCLICY_JPN from '../constants/privacyPolicy/japanase/privacyPolicy.json'
import PRYVACY_POCLICY_ENG from '../constants/privacyPolicy/english/privacyPolicy.json'
import RESIGN_JPN from '../constants/resign/japanese/resign.json'
import RESIGN_ENG from '../constants/resign/english/resign.json'
import RESIGN_OPRIONS_JPN from '../constants/resign/japanese/resignOptions.json'
import RESIGN_OPRIONS_ENG from '../constants/resign/english/resignOptions.json'
import MAILTO_JPN from '../constants/common/japanese/mailto.json'
import MAILTO_ENG from '../constants/common/english/mailto.json'
import LAYOUT_JPN from '../constants/common/japanese/layout.json'
import LAYOUT_ENG from '../constants/common/english/layout.json'
import SETTING_EMAIL_JPN from '../constants/setting/email/japanese/settingEmail.json'
import SETTING_EMAIL_ENG from '../constants/setting/email/english/settingEmail.json'
import SETTING_PASSWORD_JPN from '../constants/setting/password/japanese/settingPassword.json'
import SETTING_PASSWORD_ENG from '../constants/setting/password/english/settingPassword.json'
import SETTING_RESET_PASSWORD_JPN from '../constants/setting/reset/password/japanese/settingResetPassword.json'
import SETTING_RESET_PASSWORD_ENG from '../constants/setting/reset/password/english/settingResetPassword.json'
import SETTING_PROFILE_JPN from '../constants/setting/profile/japanese/settingProfile.json'
import SETTING_PROFILE_ENG from '../constants/setting/profile/english/settingProfile.json'
import GENDER_OPTIONS_JPN from '../constants/setting/profile/japanese/genderOptions.json'
import GENDER_OPTIONS_ENG from '../constants/setting/profile/english/genderOptions.json'
import INDUSTRY_TYPE_OPTIONS_JPN from '../constants/setting/profile/japanese/industryTypeOptions.json'
import INDUSTRY_TYPE_OPTIONS_ENG from '../constants/setting/profile/english/industryTypeOptions.json'
import NEWS_LETTER_OPTIONS_JPN from '../constants/setting/profile/japanese/newsletterOptions.json'
import NEWS_LETTER_OPTIONS_ENG from '../constants/setting/profile/english/newsletterOptions.json'
import POSITION_OPTIONS_JPN from '../constants/setting/profile/japanese/positionOptions.json'
import POSITION_OPTIONS_ENG from '../constants/setting/profile/english/positionOptions.json'
import PRIVACY_POLICY_OPTIONS_JPN from '../constants/common/japanese/privacyPolicyOptions.json'
import PRIVACY_POLICY_OPTIONS_ENG from '../constants/common/english/privacyPolicyOptions.json'
import MYPAGE_FILTER_JPN from '../constants/mypage/japanese/mypageFilter.json'
import MYPAGE_FILTER_ENG from '../constants/mypage/english/mypageFilter.json'
import EVENT_APPLY_JPN from '../constants/event/japanese/eventApply.json'
import EVENT_APPLY_ENG from '../constants/event/english/eventApply.json'
import EVENT_SEARCH_OPTIONS_JPN from '../constants/event/japanese/eventSearchOptions.json'
import EVENT_SEARCH_OPTIONS_ENG from '../constants/event/english/eventSearchOptions.json'
import EVENT_ELEMENT_JPN from '../constants/event/japanese/element.json'
import EVENT_ELEMENT_ENG from '../constants/event/english/element.json'
import EVENT_FILTER_JPN from '../constants/event/japanese/eventFilter.json'
import EVENT_FILTER_ENG from '../constants/event/english/eventFilter.json'
import JOURNAL_FILTER_JPN from '../constants/journal/japanese/journalFilter.json'
import JOURNAL_FILTER_ENG from '../constants/journal/english/journalFilter.json'
import LANDING_JPN from '../constants/landing/japanese/landing.json'
import LANDING_ENG from '../constants/landing/english/landing.json'
import GUIDANCE_JPN from '../constants/landing/japanese/guidance.json'
import GUIDANCE_ENG from '../constants/landing/english/guidance.json'

i18n.use(initReactI18next).init({
  resources: {
    eng: {
      translation: {
        questions: QUESTION_ENG,
        questionsMarudeli: [],
        contacts: CONTACT_ENG,
        terms: TERMS_ENG,
        privacyPolicy: PRYVACY_POCLICY_ENG,
        resign: RESIGN_ENG,
        resignOptions: RESIGN_OPRIONS_ENG,
        mailto: MAILTO_ENG,
        layout: LAYOUT_ENG,
        settingEmail: SETTING_EMAIL_ENG,
        settingPassword: SETTING_PASSWORD_ENG,
        settingResetPassword: SETTING_RESET_PASSWORD_ENG,
        settingProfile: SETTING_PROFILE_ENG,
        genderOptions: GENDER_OPTIONS_ENG,
        industryTypeOptions: INDUSTRY_TYPE_OPTIONS_ENG,
        newsletterOptions: NEWS_LETTER_OPTIONS_ENG,
        positionOptions: POSITION_OPTIONS_ENG,
        privacyPolicyOptions: PRIVACY_POLICY_OPTIONS_ENG,
        mypageFilter: MYPAGE_FILTER_ENG,
        eventApply: EVENT_APPLY_ENG,
        eventSearchOptions: EVENT_SEARCH_OPTIONS_ENG,
        eventElement: EVENT_ELEMENT_ENG,
        eventFilter: EVENT_FILTER_ENG,
        journalFilter: JOURNAL_FILTER_ENG,
        landing: LANDING_ENG,
        guidance: GUIDANCE_ENG
      }
    },
    jpn: {
      translation: {
        questions: QUESTION_JPN,
        questionsMarudeli: QUESTION_MARUDELI_JPN,
        contacts: CONTACT_JPN,
        terms: TERMS_JPN,
        privacyPolicy: PRYVACY_POCLICY_JPN,
        resign: RESIGN_JPN,
        resignOptions: RESIGN_OPRIONS_JPN,
        mailto: MAILTO_JPN,
        layout: LAYOUT_JPN,
        settingEmail: SETTING_EMAIL_JPN,
        settingPassword: SETTING_PASSWORD_JPN,
        settingResetPassword: SETTING_RESET_PASSWORD_JPN,
        settingProfile: SETTING_PROFILE_JPN,
        genderOptions: GENDER_OPTIONS_JPN,
        industryTypeOptions: INDUSTRY_TYPE_OPTIONS_JPN,
        newsletterOptions: NEWS_LETTER_OPTIONS_JPN,
        positionOptions: POSITION_OPTIONS_JPN,
        privacyPolicyOptions: PRIVACY_POLICY_OPTIONS_JPN,
        mypageFilter: MYPAGE_FILTER_JPN,
        eventApply: EVENT_APPLY_JPN,
        eventSearchOptions: EVENT_SEARCH_OPTIONS_JPN,
        eventElement: EVENT_ELEMENT_JPN,
        eventFilter: EVENT_FILTER_JPN,
        journalFilter: JOURNAL_FILTER_JPN,
        landing: LANDING_JPN,
        guidance: GUIDANCE_JPN
      }
    }
  },
  lng: 'jpn',
  fallbackLng: 'jpn',
  interpolation: { escapeValue: false }
})

export default i18n
