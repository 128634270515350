import { axios } from '../../utilities/axiosClient'

/**
 * ログイン画面に遷移する
 */
export type MachiPassLogin = {
  uri: string
}

export const getMachiPassLogin = (): Promise<MachiPassLogin> =>
  axios.get('/api/machi/login')
