import { axios } from '../../utilities/axiosClient'

export type Signup = {
  data?: Array<['']>
  token?: string
  referral_token?: string
  login_uri: string
  signup_uri: string
}

/**
 * 新規会員登録画面に遷移する(IDをお持ちの方、お持ちでない方の分岐画面)
 */

export const getSignup = (): Promise<Signup> => axios.get('/api/sign_up')
