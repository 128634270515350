import { useQueryClient, useMutation } from 'react-query'
import { useErrorHandler } from 'react-error-boundary'
import { axios } from '../../utilities/axiosClient'

// NOTE:本当の返り値
export type ShopFavoriteRemove = {
  favoriteCount: number
}

// NOTE:返り値が期待値と違うのでカスタムする
const promise = async (shopId: number) => {
  return shopId
}

export const useRemoveFavoriteShop = () => {
  const queryClient = useQueryClient()
  const handleError = useErrorHandler()

  const removeFavoriteShopMutation = useMutation(
    (shopId: number): Promise<number> => {
      const res = axios.post('/api/remove_favorite_shop', { shop: shopId })
      return promise(shopId)
    },
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries('getShopMypage')
        queryClient.invalidateQueries(['getShopDetail'])
      },
      onError: (error) => {
        handleError(error)
      }
    }
  )

  return { removeFavoriteShopMutation }
}
