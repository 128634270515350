import { axios } from '../../utilities/axiosClient'

export type Workplaces = {
  workplaces: Workplace[]
}

export type Workplace = {
  id: number
  name: string
  name_kane: string
}

/**
 * ビルに紐づく就業場所を取得する
 * Mypageのprofile更新で使用する
 */
export const getWorkplaces = (id: number): Promise<Workplaces> =>
  axios.post('/api/workplace', {
    building: id
  })
