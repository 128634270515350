import { lazyRetry } from '../../functions/utilities'

const MarudeliDetailProduct = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "marudeli-detail-product-page" */ '../../pages/MarudeliDetailProduct'
    )
)
const MarudeliDetailShop = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "marudeli-detail-shop-page" */ '../../pages/MarudeliDetailShop'
    )
)
const MarudeliProcedure = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "marudeli-procedure-page" */ '../../pages/MarudeliProcedure'
    )
)
const MarudeliTerms = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "marudeli-terms-page" */ '../../pages/MarudeliTerms'
    )
)
const MarudeliSpecificCommerce = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "marudeli-specific-commerce-page" */ '../../pages/MarudeliSpecificCommerce'
    )
)
const Marudeli = lazyRetry(
  () => import(/* webpackChunkName: "marudeli-page" */ '../../pages/Marudeli')
)
const Complete = lazyRetry(
  () =>
    import(/* webpackChunkName: "en-complete-page" */ '../../pages/Complete')
)

export const marudeliRoutes = [
  {
    path: '/marudeli',
    element: <Marudeli />
  },
  {
    path: '/marudeli/procedure',
    element: <MarudeliProcedure />
  },
  {
    path: '/marudeli/complete',
    element: <Complete />
  },
  {
    path: '/marudeli/:shopId',
    element: <MarudeliDetailShop />
  },
  {
    path: '/marudeli/:shopId/product/:productId',
    element: <MarudeliDetailProduct />
  },
  {
    path: '/marudeli/terms',
    element: <MarudeliTerms />
  },
  {
    path: '/marudeli/terms',
    element: <MarudeliTerms />
  },
  {
    path: '/marudeli/specificCommerce',
    element: <MarudeliSpecificCommerce />
  }
]
