import { MenuItem, TextField, CircularProgress } from '@mui/material'
import styled from '@emotion/styled'
import { UseFormRegisterReturn } from 'react-hook-form'
import { BasicLabel } from '.'
import { OptionProps } from '../../functions/types/Common'
import { useI18n } from '../../functions/hooks'

const Wrapper = styled('div')`
  display: grid;
  gap: 5px;
`
const MainText = styled('p')`
  font-size: 15px;
  font-weight: bold;
`
const SubText = styled('p')`
  font-size: 12px;
`
const LabelWrapper = styled('div')`
  align-items: center;
  display: flex;
  gap: 5px;
`
const Label = styled('label')`
  font-size: 15px;
  font-weight: 600;
`
const CustomTextField = styled(TextField)`
  width: 100%;
  .MuiOutlinedInput-root {
    background: white;
  }
`

const Placeholder = styled('p')<{ color?: string }>`
  color: ${({ color }) => color || `gray`};
`

export type SelectFormProps = {
  // NOTE:アクション
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  // NOTE:エラーハンドリング
  register?: UseFormRegisterReturn
  error?: boolean
  helperText?: string
  // NOTE:必須項目
  options: OptionProps[] | null
  // NOTE:追加項目
  id?: string
  name?: string
  size?: 'small' | 'medium'
  labelId?: string
  label?: string
  value: string | number
  placeholder?: string
  disabled?: boolean
  isLoading?: boolean
  defaultValue?: string | number
  mainText?: string
  subTexts?: string[] //複数入ることを想定
  isRequired?: boolean
  placeholderColor?: string
}

export const InputSelect: React.FC<SelectFormProps> = ({
  onChange,
  register,
  error = false,
  helperText = '',
  id,
  name,
  size,
  labelId = 'select',
  label,
  value,
  options,
  placeholder,
  disabled = false,
  isLoading = false,
  defaultValue = '',
  mainText = '',
  subTexts = [],
  isRequired = true,
  placeholderColor
}) => {
  const { isJapanese } = useI18n()
  return (
    <Wrapper>
      {label ? (
        <LabelWrapper>
          <Label id={labelId}>{label}</Label>
          <BasicLabel isRequired={isRequired}>
            {isJapanese ? '必須' : 'required'}
          </BasicLabel>
        </LabelWrapper>
      ) : null}
      {mainText ? <MainText>{mainText}</MainText> : null}
      {subTexts.length !== 0
        ? subTexts.map((subText) => <SubText key={subText}>{subText}</SubText>)
        : null}
      <CustomTextField
        value={value}
        onChange={onChange}
        id={id}
        name={name}
        size={size}
        select
        disabled={disabled}
        helperText={helperText}
        error={error}
        {...register}
        defaultValue={defaultValue}
        SelectProps={{
          displayEmpty: true,
          renderValue: () => {
            if (value === '' || value === 0) {
              return (
                <Placeholder color={placeholderColor}>
                  {placeholder}
                </Placeholder>
              )
            }
            if (options && options.length !== 0) {
              return (
                options.find((option) => option.value === value)?.label || ''
              )
            }
            return <CircularProgress size={20} />
          }
        }}
      >
        {options &&
          options.length !== 0 &&
          options.map((option) => (
            <MenuItem
              key={`${option.label}-${option.value}`}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
      </CustomTextField>
    </Wrapper>
  )
}
