import styled from '@emotion/styled'
import { mediaQuery } from '../../functions/hooks'
import {
  BaseText,
  BaseContentBlock,
  PairTextWrapper,
  PairTitleWrapper,
  PairWrapper
} from '../../functions/themes'
import { Paragraph } from '../../functions/types/Common'
import { ContentParagraph, BasicHtmlParseText } from '.'

export type PairProps = {
  title?: string
  text?: string
  url?: string
  supplement?: string
  titleMarginBottom?: number
  paragraphs?: Paragraph[] | null
  handleClick?: (actionName: string) => void
}

/**
 * PairWrapper・PairTitleWrapper・PairTextWrapperはtitleのみ使用したい、textのみ使用したいケースが
 * 多かったため分離して管理している
 */
const SupplementaryTitle = styled(BasicHtmlParseText)`
  font-size: 12px;
  margin-left: 10px;
  ${mediaQuery('tab')} {
    margin-left: 0px;
  }
`
const SupplementaryContentBlock = styled(BaseContentBlock)`
  font-size: 12px;
  margin-left: 10px;
  ${mediaQuery('tab')} {
    margin-left: 0px;
  }
`

export const Pair: React.FC<PairProps> = ({
  title,
  text,
  url = '',
  supplement,
  paragraphs,
  handleClick
}) => {
  const onClickText = () => {
    if (!url) return
    window.open(url, '_blank')
  }

  return (
    <PairWrapper>
      {title && (
        <PairTitleWrapper>
          <BaseText className="-bold">{title}</BaseText>
          <SupplementaryTitle text={supplement || ''} />
          <SupplementaryContentBlock>
            {paragraphs && handleClick && (
              <ContentParagraph
                paragraphs={paragraphs}
                handleClick={handleClick}
              />
            )}
          </SupplementaryContentBlock>
        </PairTitleWrapper>
      )}
      {url && (
        <PairTextWrapper onClick={onClickText} className="-pointer">
          <BasicHtmlParseText className="-blue" text={text || ''} />
        </PairTextWrapper>
      )}
      {!url && (
        <PairTextWrapper>
          <BasicHtmlParseText text={text || ''} />
        </PairTextWrapper>
      )}
    </PairWrapper>
  )
}
