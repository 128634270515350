import { axios } from '../../utilities/axiosClient'
import { Shops } from '../../types/Voucher'

type VoucherProps = {
  count?: number
}

/**
 * クーポンに登録されている店舗の詳細を取得する
 */
export const getVoucherShop = (params?: VoucherProps): Promise<Shops> =>
  axios.post('/api/voucher_shops', params)
