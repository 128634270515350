import { axios } from '../../utilities/axiosClient'
import { Shops } from '../../types/Voucher'

type shopOneVoucherProps = {
  count?: number
}

/**
 * クーポンに登録されている店舗の一覧を取得する
 * トップページで利用するため、店舗に対して特典が1つのみ紐づいている
 */
export const getShopOneVoucher = (
  params?: shopOneVoucherProps
): Promise<Shops> => axios.post('/api/shops_one_voucher', params)
