import { axios } from '../../../utilities/axiosClient'

export type PasswordResetLinkEmail = {
  email: string
}

/**
 * 非ログイン時のパスワード再設定メール送信(ログイン時は非表示しないとエラーになる)
 * 入力されたメールアドレスがDBに存在するかを確認してから、メールを送信している
 * メールには以下のようなURLが添付されている
 * http://localhost:3000/reset/password?token=fp1bY2XSAKQbT8pOCHPd1Hw8MJqZ4L1nQxiUTx2TcLrVmY8RzuHxnMIRdAHx
 */

export const getPasswordResetLinkEmail = (
  params: PasswordResetLinkEmail
): Promise<PasswordResetLinkEmail> => axios.post('/api/reset/email', params)
