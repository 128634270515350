import { styled } from '@mui/material/styles'
import { mediaQuery } from '../hooks'

/**
 * 英語切り替えの実装で使う
 * 基本的にContentParagraph, ContentUnorderedList, ContentOrderedListのラッパーとして使う
 */
export const BaseContentBlock = styled('div')<{ color?: string }>`
  display: grid;
  font-size: 16px;
  gap: 10px;
  word-break: break-all;
  &.-bold {
    font-weight: bold;
  }
  &.-left {
    text-align: left;
  }
  &.-center {
    text-align: center;
  }
  ${mediaQuery('sp')} {
    font-size: 14px;
  }
  li {
    list-style-type: inherit;
    white-space: pre-line;
  }
  p {
    white-space: pre-line;
  }
  span {
    color: ${({ color }) => color || '#0066ff'};
    cursor: pointer;
  }
`
