import { useMemo, useCallback } from 'react'
import { useQuery } from 'react-query'
import { getBuilding } from '../apis'
import { useI18n } from '.'

export const useBuildingOptions = () => {
  const { isJapanese } = useI18n()

  const { data: buildings } = useQuery('getBuilding', () =>
    getBuilding().then((data) => data.building)
  )

  const other = useMemo(
    () => (isJapanese ? 'その他' : 'Ｏｔｈｅｒ'),
    [isJapanese]
  )

  const buildingOptions = useMemo(() => {
    if (buildings && buildings.length !== 0) {
      return buildings.map((item) => {
        return {
          value: String(item.id),
          label: item.building_name
        }
      })
    }
    return []
  }, [buildings])

  const buildingNames = useMemo(() => {
    if (buildings) {
      return Object.values(buildings).map((item) => {
        return item.building_name
      })
    }
    return null
  }, [buildings])

  const filteredBuildingNames = useCallback(
    (targetId: number) => {
      if (buildings && buildings.length !== 0) {
        return (
          buildings.find((item) => item.id === targetId)?.building_name || other
        )
      }
      return ''
    },
    [buildings, other]
  )

  return {
    buildings,
    buildingOptions,
    buildingNames,
    filteredBuildingNames
  }
}
