import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup
} from '@mui/material'
import styled from '@emotion/styled'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { Controller, Control } from 'react-hook-form'
import CheckIcon from '@mui/icons-material/Check'
import { ErrorText, SquareIcon, SquareIconBlank, BasicLabel } from '.'
import { BaseText } from '../../functions/themes'
import { useI18n } from '../../functions/hooks'
import { MuiColorTypes } from '../../functions/types/Common'

const Wrapper = styled(FormControl)`
  display: grid;
  gap: 5px;
`
const SubText = styled(BaseText)`
  font-size: 12px;
`
const MainText = styled(BaseText)`
  font-weight: bold;
`
const LabelWrapper = styled('div')`
  align-items: center;
  display: flex;
  gap: 5px;
`
const Label = styled('label')`
  font-size: 15px;
  font-weight: bold;
`
const CustomFormControlLabel = styled(FormControlLabel)`
  display: block;
  margin-left: 0;
  margin-right: 0;
`
const CustomCheckBox = styled(Checkbox)`
  min-height: 38px;
  min-width: 38px;
  padding: 5px;
`

export interface CheckboxGroupProps {
  label?: string | ReactJSXElement
  isDisabled?: boolean
  checkboxProps: CheckboxProps[]
  size?: 'small' | 'medium'
  handleCheck: (id: string, label: string) => void
  mainText?: string
  subTexts?: string[] //複数入ることを想定
  isRequired?: boolean
  isRow?: boolean
  colorTypes?: MuiColorTypes
  // NOTE:エラーハンドリング
  error?: boolean
  helperText?: string
  // NOTE:custom icon
  icon?: JSX.Element
  checkedIcon?: JSX.Element
  control?: Control<any>
  name: any
}

export type CheckboxProps = {
  value: string
  label: string
}

export const InputCheckboxGroupControlled: React.FC<CheckboxGroupProps> = ({
  error = false,
  helperText = '',
  label = '',
  isDisabled = false,
  size = 'small',
  checkboxProps,
  handleCheck,
  mainText = '',
  subTexts = [],
  isRequired = true,
  isRow = false,
  colorTypes = 'point',
  icon = <SquareIcon content="" size={size} />,
  checkedIcon = (
    <SquareIconBlank
      content={<CheckIcon fontSize="small" color={colorTypes} />}
      size={size}
    />
  ),
  name,
  control
}) => {
  const { isJapanese } = useI18n()

  return (
    <Wrapper>
      {label ? (
        <LabelWrapper>
          <Label htmlFor="">{label}</Label>
          <BasicLabel isRequired={isRequired}>
            {isJapanese ? '必須' : 'required'}
          </BasicLabel>
        </LabelWrapper>
      ) : null}
      {mainText ? <MainText>{mainText}</MainText> : null}
      {subTexts.length !== 0
        ? subTexts.map((subText) => <SubText key={subText}>{subText}</SubText>)
        : null}
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <FormGroup row={isRow}>
              {checkboxProps.map((checkbox) => (
                <CustomFormControlLabel
                  key={checkbox.value}
                  label={checkbox.label}
                  checked={field.value.includes(checkbox.value)}
                  control={
                    <CustomCheckBox
                      size={size}
                      disabled={isDisabled}
                      icon={icon}
                      checkedIcon={checkedIcon}
                      // defaultChecked={field.value.includes(checkbox.value)}
                      onChange={() =>
                        field.onChange(
                          handleCheck(checkbox.value, checkbox.label)
                        )
                      }
                    />
                  }
                />
              ))}
            </FormGroup>
          )
        }}
      />
      <ErrorText error={error} helperText={helperText} />
    </Wrapper>
  )
}
