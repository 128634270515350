import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import cookies from 'js-cookie'
import { useLocation } from 'react-router-dom'
import {
  LayoutJson,
  SettingEmailJson,
  SettingPasswordJson,
  SettingResetPasswordJson,
  SettingProfileJson,
  QuestionJson,
  MypageFilterJson,
  EventApplyJson,
  EventElementJson,
  EventFilterJson,
  TermsJson,
  PrivacyPolicyJson,
  JournalFilterJson,
  LandingJson,
  GuidanceJson,
  ResignJson
} from '../types/I18n'
import { OptionProps } from '../types/Common'

export const useI18n = () => {
  const { t } = useTranslation()
  const lang = cookies.get('lang')
  const { pathname } = useLocation()

  const setLang = useCallback(() => {
    if (!lang) cookies.set('lang', 'eng')
    const newLang = lang === 'eng' ? 'jpn' : 'eng'
    cookies.set('lang', newLang)

    const engPath = `/en${pathname}`
    const jpnPath = pathname.slice(3)
    const generatePath = newLang === 'jpn' ? jpnPath : engPath
    window.location.replace(generatePath)
  }, [pathname, lang])

  const isJapanese = useMemo(() => {
    if (!lang) return true
    return lang === 'jpn'
  }, [lang])

  const i18nLayout: LayoutJson = t('layout', { returnObjects: true })
  const i18nSettingEmail: SettingEmailJson = t('settingEmail', {
    returnObjects: true
  })
  const i18nSettingPassword: SettingPasswordJson = t('settingPassword', {
    returnObjects: true
  })
  const i18nSettingResetPassword: SettingResetPasswordJson = t(
    'settingResetPassword',
    { returnObjects: true }
  )
  const i18nSettingProfile: SettingProfileJson = t('settingProfile', {
    returnObjects: true
  })

  const i18nGenderOptions: OptionProps[] = t('genderOptions', {
    returnObjects: true
  })
  const i18nIndustryTypeOptions: OptionProps[] = t('industryTypeOptions', {
    returnObjects: true
  })
  const i18nNewsletterOptions: OptionProps[] = t('newsletterOptions', {
    returnObjects: true
  })
  const i18nPositionOptions: OptionProps[] = t('positionOptions', {
    returnObjects: true
  })
  const i18nPrivacyPolicyOptions: OptionProps[] = t('privacyPolicyOptions', {
    returnObjects: true
  })

  const i18nQuestions: QuestionJson = t('questions', { returnObjects: true })
  const i18nQuestionsMarudeli: QuestionJson = t('questionsMarudeli', {
    returnObjects: true
  })

  const i18nMypageFilter: MypageFilterJson = t('mypageFilter', {
    returnObjects: true
  })
  const i18nEventApply: EventApplyJson = t('eventApply', {
    returnObjects: true
  })
  const i18nEventSearchOptions: OptionProps[] = t('eventSearchOptions', {
    returnObjects: true
  })
  const i18nEventElement: EventElementJson = t('eventElement', {
    returnObjects: true
  })
  const i18nEventFilter: EventFilterJson = t('eventFilter', {
    returnObjects: true
  })
  const i18nTerms: TermsJson = t('terms', {
    returnObjects: true
  })
  const i18nPrivacyPolicy: PrivacyPolicyJson = t('privacyPolicy', {
    returnObjects: true
  })
  const i18nJournalFilter: JournalFilterJson = t('journalFilter', {
    returnObjects: true
  })
  const i18nLanding: LandingJson = t('landing', {
    returnObjects: true
  })
  const i18nGuidance: GuidanceJson = t('guidance', {
    returnObjects: true
  })

  const i18nResign: ResignJson = t('resign', {
    returnObjects: true
  })
  const i18nResignOptions: OptionProps[] = t('resignOptions', {
    returnObjects: true
  })

  return {
    t,
    lang,
    setLang,
    isJapanese,
    i18nLayout,
    i18nSettingEmail,
    i18nSettingPassword,
    i18nSettingResetPassword,
    i18nSettingProfile,
    i18nGenderOptions,
    i18nIndustryTypeOptions,
    i18nNewsletterOptions,
    i18nPositionOptions,
    i18nPrivacyPolicyOptions,
    i18nQuestions,
    i18nQuestionsMarudeli,
    i18nMypageFilter,
    i18nEventApply,
    i18nEventSearchOptions,
    i18nEventElement,
    i18nEventFilter,
    i18nTerms,
    i18nPrivacyPolicy,
    i18nJournalFilter,
    i18nLanding,
    i18nGuidance,
    i18nResign,
    i18nResignOptions
  }
}
