import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import NotificationsIcon from '@mui/icons-material/Notifications'
import PersonIcon from '@mui/icons-material/Person'
import HelpIcon from '@mui/icons-material/Help'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { styled } from '@mui/material/styles'
import { MuiColorTypes } from '../../functions/types/Common'
import { mediaQuery } from '../../functions/hooks'

const NoDefaultMuiHover = styled(IconButton)`
  background: #4a4a4a;
  color: white;
  &:hover {
    background: grey;
  }
`
const BookmarkButton = styled(IconButton)<{ favoritecolor: string }>`
  background: white;
  border: 1px solid ${({ favoritecolor }) => favoritecolor};
  color: ${({ favoritecolor }) => favoritecolor};
  &:hover {
    background: grey;
  }
  ${mediaQuery('sp')} {
    &:hover {
      background: white;
    }
  }
  .MuiSvgIcon-root {
    font-size: 15px;
  }
`

interface ButtonIconProps {
  className?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  color?: MuiColorTypes
  size?: 'small' | 'medium' | 'large'
  isFavorite?: boolean
}
export const ButtonIconArrowRight: React.FC<ButtonIconProps> = ({
  className,
  onClick,
  color,
  size
}) => {
  return (
    <div className={className}>
      <IconButton onClick={onClick} color={color} size={size}>
        <ArrowRightIcon />
      </IconButton>
    </div>
  )
}
export const ButtonIconBookmark: React.FC<ButtonIconProps> = ({
  className,
  onClick,
  color,
  size,
  isFavorite
}) => {
  return (
    <div className={className}>
      <BookmarkButton
        onClick={onClick}
        favoritecolor={isFavorite ? '#f08e35' : '#4a4a4a'}
      >
        <BookmarkIcon />
      </BookmarkButton>
    </div>
  )
}
export const ButtonIconClose: React.FC<ButtonIconProps> = ({
  className,
  onClick,
  color,
  size
}) => {
  return (
    <div className={className}>
      <IconButton onClick={onClick} color={color} size={size}>
        <CloseIcon />
      </IconButton>
    </div>
  )
}
export const ButtonIconCustomClose: React.FC<ButtonIconProps> = ({
  className,
  onClick,
  color,
  size
}) => {
  return (
    <div className={className}>
      <NoDefaultMuiHover onClick={onClick} color={color} size={size}>
        <CloseIcon />
      </NoDefaultMuiHover>
    </div>
  )
}
export const ButtonIconArrowUpward: React.FC<ButtonIconProps> = ({
  className,
  onClick,
  color,
  size
}) => {
  return (
    <div className={className}>
      <NoDefaultMuiHover onClick={onClick} color={color} size={size}>
        <ArrowUpwardIcon />
      </NoDefaultMuiHover>
    </div>
  )
}
export const ButtonIconPerson: React.FC<ButtonIconProps> = ({
  className,
  onClick,
  color,
  size
}) => {
  return (
    <div className={className}>
      <IconButton onClick={onClick} color={color} size={size}>
        <PersonIcon sx={{ fontSize: 30 }} />
      </IconButton>
    </div>
  )
}

export const ButtonIconNotifications: React.FC<ButtonIconProps> = ({
  className,
  onClick,
  color,
  size
}) => {
  return (
    <div className={className}>
      <IconButton onClick={onClick} color={color} size={size}>
        <NotificationsIcon sx={{ fontSize: 30 }} />
      </IconButton>
    </div>
  )
}

export const ButtonIconHelp: React.FC<ButtonIconProps> = ({
  className,
  onClick,
  color,
  size
}) => {
  return (
    <div className={className}>
      <IconButton onClick={onClick} color={color} size={size}>
        <HelpIcon sx={{ fontSize: 30 }} />
      </IconButton>
    </div>
  )
}

export const ButtonIconCountUp: React.FC<ButtonIconProps> = ({
  className,
  onClick,
  color,
  size
}) => {
  return (
    <div className={className}>
      <IconButton onClick={onClick} color={color} size={size}>
        <AddCircleIcon sx={{ fontSize: 40, color: '#CC3C4D' }} />
      </IconButton>
    </div>
  )
}

export const ButtonIconCountDown: React.FC<ButtonIconProps> = ({
  className,
  onClick,
  color,
  size
}) => {
  return (
    <div className={className}>
      <IconButton onClick={onClick} color={color} size={size}>
        <RemoveCircleIcon sx={{ fontSize: 40, color: '#CC3C4D' }} />
      </IconButton>
    </div>
  )
}
