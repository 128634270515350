import { axios } from '../../utilities/axiosClient'
import { EventApplyConfirmResponse, Address } from '../../types/Event'

type EventApplyConfirmRequest = {
  id: number
  no1_selection_result?: string
  no1_contents_result?: string
  no2_selection_result?: string
  no2_contents_result?: string
  apply_present_id?: string // プレゼントID
} & Address

export const getEventApplyConfirm = (
  params: EventApplyConfirmRequest
): Promise<EventApplyConfirmResponse> =>
  axios.post(`/api/event/${params.id}/apply/confirm`, params)
