import * as React from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { QueryClient, QueryClientProvider, DefaultOptions } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { I18nextProvider } from 'react-i18next'
import { ErrorForbidden, Loading } from '../components/uis'
import { theme } from '../functions/utilities/muiThemeClient'
import { AuthProvider } from '../functions/utilities'
import i18n from '../functions/utilities/i18nClient'
// NOTE:react-query-authでログインユーザーの判定をしているのでcontextAPIを使った判定はやめた
// import { AuthContextProvider } from '../functions/contexts/AuthContext'

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: Infinity // キャッシュしたデータが古くなったとみなす時間
  }
}

const queryClient = new QueryClient({ defaultOptions: queryConfig })

export const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary
}) => {
  return (
    <ErrorForbidden error={error} resetErrorBoundary={resetErrorBoundary} />
  )
}

type AppProviderProps = {
  children: React.ReactNode
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  return (
    <React.Suspense fallback={<Loading />}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <AuthProvider>
                <I18nextProvider i18n={i18n}>
                  <BrowserRouter>{children}</BrowserRouter>
                </I18nextProvider>
              </AuthProvider>
            </QueryClientProvider>
          </HelmetProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </React.Suspense>
  )
}
