import styled from '@emotion/styled'
import CheckIcon from '@mui/icons-material/Check'
import { mediaQuery, useCreditCard, useBoolean } from '../../functions/hooks'
import { BaseText } from '../../functions/themes'
import { PairBetween, Button, CircleIcon } from '.'

const FlexWrapper = styled('div')`
  align-items: center;
  display: flex;
  gap: 50px;
  justify-content: space-between;
  ${mediaQuery('sp')} {
    gap: 15px;
  }
`
const Block = styled('div')`
  align-items: center;
  border: 1px solid #444;
  border-radius: 10px;
  display: grid;
  margin: auto;
  padding: 30px 40px;
  width: 70%;
  &.-active {
    background: #ccc;
  }
  &.-radio {
    cursor: pointer;
  }
  ${mediaQuery('sp')} {
    border-radius: 10px;
    padding: 15px;
    width: 100%;
  }
`
const Text = styled(BaseText)`
  &.-large {
    font-size: 25px;
    ${mediaQuery('sp')} {
      font-size: 15px;
    }
  }
  &.-medium {
    font-size: 18px;
  }
  &.-small {
    color: grey;
    font-size: 12px;
  }
`

export type Card = {
  cardExpire: string //'01/23'
  cardId: string //'81IWUHMDUGW92LQKRJGBWL3ND'
  cardNumber: string //'411111********11'
  defaultCard: string //'1'
}

export type CreditCardProps = {
  card: Card
  handleClick?: (card: Card) => void
  type?: 'normal' | 'button' | 'radio'
  value?: string
}

export const CreditCard: React.FC<CreditCardProps> = ({
  card,
  handleClick,
  type = 'normal',
  value
}) => {
  const { getCardBrancdIcon } = useCreditCard()
  const { isResponsive } = useBoolean()
  const isSelected = value === card.cardId

  return (
    <>
      {handleClick && type === 'radio' && (
        <Block
          className={isSelected ? '-active -radio' : '-radio'}
          onClick={() => handleClick(card)}
        >
          <FlexWrapper>
            <CircleIcon
              content={
                <CheckIcon
                  color="base"
                  fontSize={isResponsive ? 'small' : 'medium'}
                />
              }
              size={isResponsive ? 'small' : 'medium'}
              className={isSelected ? '-bg-black' : ''}
            />
            <FlexWrapper>
              {getCardBrancdIcon(card.cardNumber)}
              <Text className="-large">{card.cardNumber}</Text>
            </FlexWrapper>
            <div>
              <Text className="-small">有効期限</Text>
              <Text>{card.cardExpire}</Text>
            </div>
          </FlexWrapper>
        </Block>
      )}

      {type === 'normal' && (
        <Block>
          <PairBetween
            leftItem={
              <FlexWrapper>
                {getCardBrancdIcon(card.cardNumber)}
                <Text className="-large">{card.cardNumber}</Text>
              </FlexWrapper>
            }
            rightItem={
              <div>
                <Text className="-small">有効期限</Text>
                <Text>{card.cardExpire}</Text>
              </div>
            }
          />
        </Block>
      )}

      {handleClick && type === 'button' && (
        <Block key={card.cardId}>
          <PairBetween
            leftItem={
              <FlexWrapper>
                {getCardBrancdIcon(card.cardNumber)}
                <Text className="-large">{card.cardNumber}</Text>
                <div>
                  <Text className="-small">有効期限</Text>
                  <Text>{card.cardExpire}</Text>
                </div>
              </FlexWrapper>
            }
            rightItem={
              <Button
                size="small"
                variant="outlined"
                onClick={() => handleClick(card)}
              >
                削除
              </Button>
            }
          />
        </Block>
      )}
    </>
  )
}
