import { MenuItem, TextField, Checkbox } from '@mui/material'
import styled from '@emotion/styled'
import { UseFormRegisterReturn } from 'react-hook-form'
import CheckIcon from '@mui/icons-material/Check'
import { BasicLabel, SquareIcon, SquareIconBlank } from '.'
import { useI18n } from '../../functions/hooks'
import { MuiColorTypes, OptionProps } from '../../functions/types/Common'

const Wrapper = styled('div')`
  display: grid;
  gap: 5px;
`
const MainText = styled('p')`
  font-size: 15px;
  font-weight: bold;
`
const SubText = styled('p')`
  font-size: 12px;
`
const LabelWrapper = styled('div')`
  align-items: center;
  display: flex;
  gap: 5px;
`
const Label = styled('label')`
  font-size: 15px;
  font-weight: 600;
`
const CustomTextField = styled(TextField)`
  width: 100%;
  .MuiOutlinedInput-root {
    background: white;
  }
`
const CustomCheckBox = styled(Checkbox)`
  min-height: 38px;
  min-width: 38px;
  padding: 5px;
`
const Placeholder = styled('p')`
  color: gray;
`

export type InputSelectMultipleProps = {
  // NOTE:アクション
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  // NOTE:エラーハンドリング
  register?: UseFormRegisterReturn
  error?: boolean
  helperText?: string
  // NOTE:必須項目
  value: string[]
  options: OptionProps[]
  colorTypes?: MuiColorTypes
  // NOTE:追加項目
  id?: string
  name?: string
  labelId?: string
  label?: string
  placeholder?: string
  disabled?: boolean
  isLoading?: boolean
  mainText?: string
  subTexts?: string[] //複数入ることを想定
  isRequired?: boolean
  icon?: JSX.Element
  checkedIcon?: JSX.Element
  size?: 'small' | 'medium'
}

/**
 * e.target.valueはReact.ChangeEvent<HTMLInputElement>の型定義でstringで返るが
 * 実際はstring[]として増減した状態で返されるので直接更新関数にe.target.valueを入れるだけで
 * 処理を終わらせることができる。
 * ==============================================================
 * 使わない！！
 * ==============================================================
 */
export const InputSelectMultiple: React.FC<InputSelectMultipleProps> = ({
  onChange,
  register,
  error = false,
  helperText = '',
  id,
  name,
  labelId = 'select',
  label,
  value,
  options,
  placeholder,
  disabled = false,
  isLoading = false,
  mainText = '',
  subTexts = [],
  colorTypes = 'point',
  size = 'small',
  isRequired = true,
  icon = <SquareIcon content="" size={size} />,
  checkedIcon = (
    <SquareIconBlank
      content={<CheckIcon fontSize="small" color={colorTypes} />}
      size={size}
    />
  )
}) => {
  const { isJapanese } = useI18n()
  return (
    <Wrapper>
      {label ? (
        <LabelWrapper>
          <Label id={labelId}>{label}</Label>
          <BasicLabel isRequired={isRequired}>
            {isJapanese ? '必須' : 'required'}
          </BasicLabel>
        </LabelWrapper>
      ) : null}
      {mainText ? <MainText>{mainText}</MainText> : null}
      {subTexts.length !== 0
        ? subTexts.map((subText) => <SubText key={subText}>{subText}</SubText>)
        : null}
      <CustomTextField
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        helperText={helperText}
        error={error}
        {...register}
        select
        SelectProps={{
          displayEmpty: true,
          multiple: true,
          renderValue: (selected: any) => {
            if (value.length === 0)
              return <Placeholder>{placeholder}</Placeholder>
            return selected.join(',')
          }
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.label}>
            <CustomCheckBox
              checked={value.includes(option.label)}
              value={option.label}
              icon={icon}
              checkedIcon={checkedIcon}
            />
            {option.label}
          </MenuItem>
        ))}
      </CustomTextField>
    </Wrapper>
  )
}
