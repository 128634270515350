import { axios } from '../../utilities/axiosClient'

export type CategoriesAndTitle = {
  categories: Categories
  recommended_title?: string
}

export type Categories = Record<number, string>

export const getJournalCategoriesAndTitle = (): Promise<CategoriesAndTitle> =>
  axios.get('/api/journal')
