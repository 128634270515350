import { axios } from '../../utilities/axiosClient'

export type MachiPassLink = {
  alignmentFlg: boolean
  access_token?: string
  token_type?: string
  expires_in?: number
  uri?: string
  sub?: string
  machipass_access_token?: string // machipass連携用トークン
  machipass_expire?: string // machipass連携用有効期限
  machipass_refresh_token?: string // machipass連携用リフレッシュトークン
}
export type MachiPassLinkProps = {
  state: string
  code: string
}

/**
 * updateMarunouchiとMachipassの連携画面への遷移(作成する必要あり)
 * ログイン(machi/login)の指定リダイレクト先
 * 連携処理(getMachiPassLinkComplete)失敗時にも再度updateMarunouchi側でapiを叩き、ユーザーに連携画面を表示する
 */
export const getMachiPassLink = (
  params: MachiPassLinkProps
): Promise<MachiPassLink> =>
  axios.get(`/api/machi/link/login?state=${params.state}&code=${params.code}`)
