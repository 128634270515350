import { useQueryClient, useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { useErrorHandler } from 'react-error-boundary'
import { Journal, JournalDetail } from '../../types/Journal'
import { axios } from '../../utilities/axiosClient'

// NOTE:本当の返り値
export type JournalFavoriteAdd = {
  favoriteCount: number
}
export type JounalDetailPamras = {
  journalId: string
}

const promise = async (id: number) => id

export const useAddFavoriteJournal = () => {
  const queryClient = useQueryClient()
  const handleError = useErrorHandler()
  const { journalId } = useParams<JounalDetailPamras>()

  const addFavoriteJournalMutation = useMutation(
    (id: number): Promise<number> => {
      const res = axios.post('/api/add_favorite_journal', {
        journal_id: id
      })
      return promise(id)
    },
    {
      onMutate: async (id) => {
        await queryClient.cancelQueries({ queryKey: ['getJournal'] })
        await queryClient.cancelQueries({
          queryKey: ['getJournalDetail', journalId]
        })

        const snapshotOfPreviousJournal = queryClient.getQueryData([
          'getJournal'
        ])
        const snapshotOfPreviousJournalDetail = queryClient.getQueryData([
          'getJournalDetail',
          journalId
        ])

        if (snapshotOfPreviousJournal) {
          queryClient.setQueryData('getJournal', (journals?: Journal[]) =>
            journals!.map((journal) => {
              if (journal.id === id) {
                return {
                  ...journal,
                  favorite_flag: !journal.favorite_flag
                }
              }
              return journal
            })
          )
        }

        if (snapshotOfPreviousJournalDetail) {
          queryClient.setQueryData(
            ['getJournalDetail', journalId],
            (journalDetail?: JournalDetail) => {
              const { journal, footerJournalList } = journalDetail!
              const { favorite_flag, favorites_count, ...rest } = journal

              const newFooterJournalList = footerJournalList.map((journal) => {
                if (journal.id === id) {
                  return {
                    ...journal,
                    favorite_flag: !journal.favorite_flag
                  }
                }
                return journal
              })

              if (Number(journalId) === id) {
                return {
                  footerJournalList,
                  journal: {
                    ...rest,
                    favorites_count: favorites_count + 1,
                    favorite_flag: !favorite_flag
                  }
                }
              }
              return {
                footerJournalList: newFooterJournalList,
                journal
              }
            }
          )
        }

        return { snapshotOfPreviousJournal, snapshotOfPreviousJournalDetail }
      },
      onSuccess: () => {
        queryClient.invalidateQueries('getJournalMypage')
        queryClient.invalidateQueries('getJournalTop')
      },
      onError: (error) => {
        handleError(error)
      }
    }
  )

  return { addFavoriteJournalMutation }
}
