import styled from '@emotion/styled'
import { mediaQuery } from '../../functions/hooks'

/**
 * 左右均等に端に寄せる
 * 高さは真ん中
 */
const BetweenWrapper = styled('div')<{ gap?: number }>`
  align-items: center;
  display: flex;
  gap: ${({ gap }) => `${gap}px`};
  justify-content: space-between;
  ${mediaQuery('sp')} {
    gap: 15px;

    &.-flex-column {
      align-items: start;
      flex-direction: column;
    }
  }
`
type PairBetweenProps = {
  leftItem: JSX.Element
  rightItem: JSX.Element
  gap?: number
  flexColumn?: boolean
}

export const PairBetween: React.FC<PairBetweenProps> = ({
  leftItem,
  rightItem,
  gap = 0,
  flexColumn = false
}) => (
  <BetweenWrapper gap={gap} className={flexColumn ? '-flex-column' : ''}>
    <div>{leftItem}</div>
    <div>{rightItem}</div>
  </BetweenWrapper>
)
