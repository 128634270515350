import { TextField, Autocomplete } from '@mui/material'
import styled from '@emotion/styled'
import { UseFormRegisterReturn, Controller, Control } from 'react-hook-form'
import { BasicLabel } from '.'
import { OptionProps } from '../../functions/types/Common'
import { useI18n } from '../../functions/hooks'
import {
  formatHalfWidth,
  formatHalfWidthSpaces,
  formatHalfWidthHyphen
} from '../../functions/helpers'

const Wrapper = styled('div')`
  display: grid;
  gap: 5px;
`
const MainText = styled('p')`
  font-size: 15px;
  font-weight: bold;
`
const SubText = styled('p')`
  font-size: 12px;
`
const LabelWrapper = styled('div')`
  align-items: center;
  display: flex;
  gap: 5px;
`
const Label = styled('label')`
  font-size: 15px;
  font-weight: 600;
`
const CustomTextField = styled(TextField)`
  width: 100%;
  .MuiOutlinedInput-root {
    background: white;
  }
`

export type InputAutocompleteProps = {
  onChange?: (bool: boolean) => void
  onInputChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: string | null
  ) => void
  // NOTE:エラーハンドリング
  register?: UseFormRegisterReturn
  error?: boolean
  helperText?: string
  control?: Control<any>
  // NOTE:必須項目
  options: OptionProps[]
  name: any
  // NOTE:追加項目
  id?: string
  label?: string
  // localValue: string
  placeholder?: string
  disabled?: boolean
  isLoading?: boolean
  mainText?: string
  subTexts?: string[] //複数入ることを想定
  isRequired?: boolean
  readOnly?: boolean
}

export const InputAutocompleteControlled: React.FC<InputAutocompleteProps> = ({
  onChange,
  onInputChange,
  register,
  error = false,
  helperText = '',
  id,
  label,
  control,
  name,
  // localValue,
  options,
  placeholder,
  disabled = false,
  isLoading = false,
  mainText = '',
  subTexts = [],
  isRequired = true,
  readOnly = false
}) => {
  const { isJapanese } = useI18n()

  return (
    <Wrapper>
      {label ? (
        <LabelWrapper>
          <Label>{label}</Label>
          <BasicLabel isRequired={isRequired}>
            {isJapanese ? '必須' : 'required'}
          </BasicLabel>
        </LabelWrapper>
      ) : null}
      {mainText ? <MainText>{mainText}</MainText> : null}
      {subTexts.length !== 0
        ? subTexts.map((subText) => <SubText key={subText}>{subText}</SubText>)
        : null}

      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const isVisible = field.value.length > 1

          const formatOptions = options.filter((option) => {
            const halfWidthSpacesLabel = formatHalfWidthSpaces(option.label)
            const halfWidthHyphenLabel =
              formatHalfWidthHyphen(halfWidthSpacesLabel)
            const halfWidthLabel = formatHalfWidth(halfWidthHyphenLabel)

            return (
              halfWidthLabel
                .toLowerCase()
                .indexOf(field.value.toLowerCase()) !== -1
            )
          })

          return (
            <Autocomplete
              ref={field.ref}
              value={field.value}
              freeSolo
              filterOptions={(x) => x}
              onInputChange={onInputChange}
              options={isVisible ? formatOptions : []}
              renderInput={(params) => {
                return (
                  <CustomTextField
                    {...params}
                    error={error}
                    helperText={helperText}
                    // {...register} //エラーハンドリング周りの同期で必要になる
                  />
                )
              }}
            />
          )
        }}
      />
    </Wrapper>
  )
}
