import { useQueryClient, useMutation } from 'react-query'
import { useErrorHandler } from 'react-error-boundary'
import { axios } from '../../utilities/axiosClient'
import {
  Multiples,
  Singles,
  Texts,
  OtherSingles,
  OtherMultiples
} from '../../types/Questionnaire'

/**
 * 戻り値は無し
 * actionは修正する(前に戻る)か次に進むをサーバー側で判断するための変数であるために使わなそう
 */

type getQuestionnaireAnswerProps = {
  questionnaire_id: number
  singles?: Array<Singles>
  multiples?: Array<Multiples>
  texts?: Array<Texts>
  action?: string
  other_singles?: OtherSingles
  other_multiples?: OtherMultiples
}

const promise = async (id: number) => id

export const useQuestionnaireAnswer = () => {
  const queryClient = useQueryClient()
  const handleError = useErrorHandler()

  const questionnaireAnswerMutation = useMutation(
    (params: getQuestionnaireAnswerProps): Promise<number> => {
      const res = axios.post('/api/questionnaire/answer', { params })
      return promise(params.questionnaire_id)
    },
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries('getQuestionnaire')
        queryClient.invalidateQueries(['getQuestionnaireDetail', String(id)])
      },
      onError: (error) => {
        handleError(error)
      }
    }
  )

  return { questionnaireAnswerMutation }
}
