import { Checkbox, FormControlLabel, FormControl } from '@mui/material'
import styled from '@emotion/styled'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import CheckIcon from '@mui/icons-material/Check'
import { SquareIcon, SquareIconBlank } from '.'
import { MuiColorTypes } from '../../functions/types/Common'

const Wrapper = styled(FormControl)`
  display: grid;
  gap: 5px;
`
const Label = styled('label')`
  font-size: 15px;
  font-weight: bold;
`
const CustomFormControlLabel = styled(FormControlLabel)`
  display: block;
  margin-left: 0;
  margin-right: 0;
`
const CustomCheckBox = styled(Checkbox)`
  min-height: 38px;
  min-width: 38px;
  padding: 5px;
`

export interface InputCheckboxDiscloseProps {
  title?: string
  label: string | ReactJSXElement
  isDisabled?: boolean
  size?: 'small' | 'medium'
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  colorTypes?: MuiColorTypes
  icon?: JSX.Element
  checkedIcon?: JSX.Element
}

export const InputCheckboxDisclose: React.FC<InputCheckboxDiscloseProps> = ({
  title = '',
  label = '',
  isDisabled = false,
  size = 'small',
  onChange,
  colorTypes = 'point',
  icon = <SquareIcon content="" size={size} />,
  checkedIcon = (
    <SquareIconBlank
      content={<CheckIcon fontSize="small" color={colorTypes} />}
      size={size}
    />
  )
}) => {
  return (
    <Wrapper>
      {title && <Label>{title}</Label>}
      <CustomFormControlLabel
        control={
          <CustomCheckBox
            disabled={isDisabled}
            size={size}
            icon={icon}
            checkedIcon={checkedIcon}
            onChange={onChange}
          />
        }
        label={label}
        labelPlacement="start"
      />
    </Wrapper>
  )
}
