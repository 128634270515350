import { axios } from '../../utilities/axiosClient'
import { Data } from '../../types/Notification'

export type NotificationDetail = {
  info: Data
}

export const getNotificationDetail = (
  id: number
): Promise<NotificationDetail> => axios.get(`/api/info/${id}`)
