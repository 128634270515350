import { styled } from '@mui/material/styles'
import { css } from '@emotion/css'
import { useCallback } from 'react'
import { useI18n, useNavigate, mediaQuery } from '../../functions/hooks'
import { BasicModal, ContentParagraph } from '.'
import Banner from '../../assets/icon/login/icon_login_banner.png'
import MachiPass from '../../assets/image/image_machi_pass.png'
import {
  BaseText,
  BaseContainer,
  BaseContentBlock
} from '../../functions/themes'

const MachiPassModalContainer = styled(BaseContainer)`
  display: block;
  width: 100%;
`
const ImageWrapper = styled('div')`
  margin-top: 24px;
  text-align: center;
  ${mediaQuery('sp')} {
    margin-top: 20px;
  }
`
const styledBannerIcon = css`
  display: inline-block;
  margin: auto;
  max-width: 100%;
`
const LinkWrapper = styled('div')`
  display: grid;
  gap: 16px;
  margin-top: 32px;
  text-align: center;
  ${mediaQuery('sp')} {
    margin-top: 20px;
  }
`
const CustomSpan = styled('span')`
  color: #0066ff;
  cursor: pointer;
`

const ModalFlex = styled('div')`
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: center;
  ${mediaQuery('sp')} {
    display: block;
  }
`

type ContentModalForMachipassProps = {
  isOpen: boolean
  close: () => void
  loginUri: string
  signupUri: string
}

export const ContentModalForMachipass: React.FC<
  ContentModalForMachipassProps
> = ({ isOpen, close, loginUri, signupUri }) => {
  const { isJapanese, i18nSettingProfile: i18nMypageProfile } = useI18n()

  const { pushToNewCityVisions } = useNavigate()

  const handleClick = useCallback(
    (action: string) => {
      if (action === 'pushToNewCityVisions') pushToNewCityVisions()
    },
    [pushToNewCityVisions]
  )

  const onClickRegisterForm = () => {
    if (loginUri) window.location.replace(loginUri)
  }
  const onClickRegisterFormNoId = () => {
    if (signupUri) window.location.replace(signupUri)
  }

  return (
    <BasicModal
      title={
        isJapanese ? (
          <ModalFlex>
            <img src={MachiPass} alt="" />
            <p>と連携してください。</p>
          </ModalFlex>
        ) : (
          <ModalFlex>
            <p>Please cooperate with</p>
            <img src={MachiPass} alt="" />
          </ModalFlex>
        )
      }
      size="medium"
      open={isOpen}
      handleOpen={close}
      handleCloseWithIcon={close}
      contents={
        <MachiPassModalContainer>
          <BaseContentBlock>
            <ContentParagraph
              paragraphs={i18nMypageProfile.form.machipass.paragraphs}
              handleClick={handleClick}
            />
            <ImageWrapper onClick={pushToNewCityVisions}>
              <img src={Banner} className={styledBannerIcon} alt="" />
            </ImageWrapper>
            <LinkWrapper>
              {isJapanese ? (
                <BaseText>
                  Machi PassのIDをお持ちの方は
                  <CustomSpan onClick={onClickRegisterForm} aria-hidden="true">
                    こちら
                  </CustomSpan>
                </BaseText>
              ) : (
                <BaseText>
                  If you have already linked to Machi Pass, click
                  <CustomSpan onClick={onClickRegisterForm} aria-hidden="true">
                    here
                  </CustomSpan>
                </BaseText>
              )}
              {isJapanese ? (
                <BaseText>
                  Machi PassのIDをお持ちでない方は
                  <CustomSpan
                    onClick={onClickRegisterFormNoId}
                    aria-hidden="true"
                  >
                    こちら
                  </CustomSpan>
                </BaseText>
              ) : (
                <BaseText>
                  If you have not yet connected with Machi Pass, click
                  <CustomSpan
                    onClick={onClickRegisterFormNoId}
                    aria-hidden="true"
                  >
                    here
                  </CustomSpan>
                </BaseText>
              )}
            </LinkWrapper>
          </BaseContentBlock>
        </MachiPassModalContainer>
      }
    />
  )
}
