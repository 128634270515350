import { axios } from '../../utilities/axiosClient'
import { AdditionalInfoResponse } from './additionalInfoConfirm'

export type AdditionalInfoConfirmProps = {
  data: AdditionalInfoResponse
}

export type MachiPassLinkCompleteResponse = {
  access_token: string
  token_type: string
  expires_in: string
}

/**
 * updateMarunouchiとMachipassを紐づけるAPI
 * 正しい処理が完了後に、連携完了画面にリダイレクトさせる
 */
export const getMachiPassLinkComplete = (
  params: AdditionalInfoConfirmProps
): Promise<MachiPassLinkCompleteResponse> =>
  axios.post('/api/link/complete', params)
