import { axios } from '../../utilities/axiosClient'

export type MypageEdit = {
  birth_date: string
  email: string
  first_name_jpn: string
  first_name_eng?: string
  first_name_kana: string
  id: number
  last_name_jpn: string
  last_name_eng?: string
  last_name_kana: string
  registered_lang_type: number
  worker_id: number
  workplace: string
  workplace_name_jpn?: string
  workplace_name_eng?: string
  newsletter: number // メルマガ購読状況(0 or 1) 0の場合は購読解除、1の場合は購読
  machipass_flg: number // MachiPass連携フラグ
} & MypageEditForm &
  ActionLog

export type MypageEditForm = {
  first_name_jpn: string
  first_name_kana: string
  last_name_jpn: string
  last_name_kana: string
  gender_div: number
  building_id: number
  workplace_id: number
  position_id: number
  industry_type_id: number
  is_subscribed_to_newsletter: number //これないけどいったん入れている
}

// mypageProfileで最終的にpostする値
export type MypageProfileRequest = MypageEditNameFormRequest &
  MypageEditFormRequest

export type MypageEditNameFormRequest = {
  first_name_jpn: string
  first_name_kana: string
  first_name_eng: string
  last_name_jpn: string
  last_name_kana: string
  last_name_eng: string
}

export type MypageEditFormRequest = {
  gender_div: string
  building_id: string
  workplace_id: string
  position_id: string
  industry_type_id: string
  is_subscribed_to_newsletter: string //これないけどいったん入れている
  company: string
  workplace_name: string
}

export type ActionLog = {
  canceled_datetime?: string
  canceled_id?: string
  changed_datetime?: string
  changed_id?: number
  registered_datetime?: string
  registered_id?: number
  updated_at?: string
  updated_id?: number
  deleted_at?: string
  created_at?: string
}

/**
 * useMypageのprofile情報では足りないためより詳細情報を持っているuseEditMypageを使っている
 * いずれ統一させたい
 * profileの更新で使用する
 */
export const getMypageEdit = (): Promise<MypageEdit> =>
  axios.get('/api/edit/input')
