import { useCallback, useEffect, useState } from 'react'
import { useI18n } from '.'

enum MAILTO_TYPE {
  SUPPORT,
  ID_HELPDESK,
  MARUDELI_SUPPORT
}

type MailtoJson = {
  email: string
  subject: string
  bodyForMac: string
  bodyForWindows: string
}

export const useMailto = () => {
  const { t } = useI18n()
  const [isMac, setIsMac] = useState<boolean>(false)

  const mailto: MailtoJson[] = t('mailto', { returnObjects: true })

  /**
   * FIXME:メール本文が確定してからutf8エンコードとshift-jisエンコードに着手する
   */
  const executeMailto = useCallback(
    (mailto: MailtoJson) => {
      const { email, subject, bodyForMac, bodyForWindows } = mailto
      if (isMac) {
        window.location.href = `mailto:${email}?subject=${subject}&body=${bodyForMac}`
        return
      }
      window.location.href = `mailto:${email}?subject=${subject}&body=${bodyForWindows}`
    },
    [isMac]
  )

  const handleMailto = (email: string) => {
    if (email === 'support@marunouchi-workers.com') {
      executeMailto(mailto[MAILTO_TYPE.SUPPORT])
    }
    if (email === 'id-helpdesk@mec.co.jp') {
      executeMailto(mailto[MAILTO_TYPE.ID_HELPDESK])
    }

    if (email === 'marudeli_support@marunouchi-workers.com') {
      executeMailto(mailto[MAILTO_TYPE.MARUDELI_SUPPORT])
    }
  }

  useEffect(() => {
    const ua = window.navigator.userAgent.toLowerCase()
    if (ua.indexOf('windows nt') !== -1) {
      setIsMac(false)
      return
    }
    setIsMac(true)
  }, [])

  return { handleMailto }
}
