import { styled } from '@mui/material/styles'
import { useMemo, memo } from 'react'
import { mediaQuery } from '../../functions/hooks'
import { BaseText, BaseTitle } from '../../functions/themes'

const TitleWrapper = styled('div')`
  margin-bottom: 50px;
  position: relative;
  ${mediaQuery('sp')} {
    display: none;
  }
`
const BackgroudTitle = styled(BaseTitle)<{ fontSize?: string }>`
  font-size: 70px;
  font-size: ${({ fontSize: size }) => size || '70px'};
  text-align: left;
`
const TextWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  left: 0;
  position: absolute;
  top: 60%;
`
const Description = styled(BaseText)<{ color?: string }>`
  color: ${({ color }) => color || ``};
  font-size: 14px;
  margin: auto 24px;
`
export interface ContentTitleProps {
  icon: string
  title: string
  backgroudTitle: string
  description?: string
  descriptionColor?: string
  fontSize?: string
}

/**
 * journal, voucher, event, questionnaire, marudeliの5つのコンテンツで使用している
 * ContentTitleの目的はコンポーネントとして切り出してスコープを限定するため
 * 可読性を高めるため
 * ファーストビューに表示されるのでlazy-loadなしで実装
 * 多分lazy-load-comp使っているからフリップしていた
 */
export const ContentTitle: React.FC<ContentTitleProps> = memo(
  ({
    icon,
    title,
    description,
    descriptionColor,
    backgroudTitle,
    fontSize
  }) => {
    const isQuestionnaire = useMemo(
      () => backgroudTitle === 'Questionnaire',
      [backgroudTitle]
    )
    return (
      <TitleWrapper>
        <BackgroudTitle
          fontSize={fontSize}
          className={isQuestionnaire ? '-blue' : '-white'}
        >
          {backgroudTitle}
        </BackgroudTitle>
        <TextWrapper>
          <img src={icon} alt={`content-title-${title}`} />
          <BaseTitle>{title}</BaseTitle>
          {description !== '' ? (
            <Description className="-bold" color={descriptionColor}>
              <p>{description}</p>
            </Description>
          ) : (
            ''
          )}
        </TextWrapper>
      </TitleWrapper>
    )
  }
)
