import styled from '@emotion/styled'
import { FallbackProps } from 'react-error-boundary'
import { useMemo } from 'react'
import cookies from 'js-cookie'
import { ButtonRound, BasicHtmlParseText } from './index'
import { BaseTitle, BaseText } from '../../functions/themes'

const ContentWrapper = styled('div')`
  align-items: center;
  background: rgba(74, 74, 74, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  min-height: 100vh;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
`
const Content = styled('div')`
  background-color: white;
  border-radius: 10px;
  display: grid;
  max-width: 1100px;
  padding: 60px;
  row-gap: 30px;
`
const FooterContent = styled('div')`
  display: flex;
  gap: 30px;
  justify-content: center;
`

export const ErrorForbidden: React.FC<Partial<FallbackProps>> = ({
  error,
  resetErrorBoundary
}) => {
  const lang = cookies.get('lang')

  const isJapanese = useMemo(() => {
    if (!lang) return true
    return lang === 'jpn'
  }, [lang])

  const pushToTop = () => {
    window.location.href = isJapanese ? '/' : '/en/'
  }

  /**
   * react-routerで繊維するのではなくwidndowオブジェクトを使って遷移する
   * browserrouterより上の階層で使うことが想定されるためreact-routerでの繊維ができないため
   * react-error-boundlyのfallbackがIframeでラップされるためreset CSSでIframeを無効化する必要がある
   */
  return (
    <ContentWrapper>
      {!error && (
        <Content>
          <BaseTitle>
            {isJapanese
              ? 'アクセスしようとしたページが見つかりません'
              : 'The page you are trying to access cannot be found.'}
          </BaseTitle>

          {isJapanese ? (
            <BaseText>
              <p>以下の原因が考えられます。</p>
              <p> 1.URLが間違っているか、古い</p>
              <p>2.権限が与えられていない操作</p>
            </BaseText>
          ) : (
            <BaseText>
              <p>The following are possible causes.</p>
              <p>1. Incorrect or outdated URL</p>
              <p>2. Unauthorized operations</p>
            </BaseText>
          )}

          {isJapanese ? (
            <BaseText>
              <p>ご不便をおかけしますが、アドレスをご確認いただくか、</p>
              <p>トップ画面へ戻り本サービスをご利用ください。</p>
            </BaseText>
          ) : (
            <BaseText>
              <p>please return to the top page to use this service.</p>
            </BaseText>
          )}

          <FooterContent>
            <ButtonRound onClick={pushToTop} isReversible>
              {isJapanese ? 'トップページへ戻る' : 'To the Top Page'}
            </ButtonRound>
            {/* {resetErrorBoundary && (
              <ButtonRound onClick={resetErrorBoundary}>
                もう一度試す
              </ButtonRound>
            )} */}
          </FooterContent>
        </Content>
      )}

      {error && (
        <Content>
          <BaseTitle>
            {isJapanese ? 'エラーが発生しました' : 'An error has occurred.'}
          </BaseTitle>
          <BasicHtmlParseText text={(error as any)?.data?.message} />

          {isJapanese ? (
            <BaseText>
              <p>ご不便をおかけしますが、アドレスをご確認いただくか、</p>
              <p>トップ画面へ戻り本サービスをご利用ください。</p>
            </BaseText>
          ) : (
            <BaseText>
              <p>please return to the top page to use this service.</p>
            </BaseText>
          )}

          <FooterContent>
            <ButtonRound onClick={pushToTop} isReversible>
              {isJapanese ? 'トップページへ戻る' : 'To the Top Page'}
            </ButtonRound>
            {/* {resetErrorBoundary && (
              <ButtonRound onClick={resetErrorBoundary}>
                もう一度試す
              </ButtonRound>
            )} */}
          </FooterContent>
        </Content>
      )}
    </ContentWrapper>
  )
}
