import styled from '@emotion/styled'
import TextField from '@mui/material/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers'
import { UseFormRegisterReturn } from 'react-hook-form'
import ja from 'date-fns/locale/ja'
import { useState } from 'react'
import { BasicLabel } from '.'
import { useI18n } from '../../functions/hooks'

const Wrapper = styled('div')`
  display: grid;
  gap: 5px;
`
const SubText = styled('p')`
  font-size: 12px;
`
const MainText = styled('p')`
  font-size: 15px;
  font-weight: bold;
`
const LabelWrapper = styled('div')`
  align-items: center;
  display: flex;
  gap: 5px;
`
const Label = styled('label')`
  font-size: 15px;
  font-weight: 600;
`
const CustomTextField = styled(TextField)`
  width: 100%;
  .MuiOutlinedInput-root {
    background: white;
  }
`

export type InputDatePickerProps = {
  label?: string
  value: Date | null
  onChange: (date: Date | null) => void
  register?: UseFormRegisterReturn
  error?: boolean
  helperText?: string
  minDate?: Date
  maxDate?: Date
  placeholder?: string
  shouldDisableDate?: (date: Date) => boolean
  mainText?: string
  subTexts?: string[] //複数入ることを想定
  isRequired?: boolean
}

export const InputDatepicker: React.FC<InputDatePickerProps> = ({
  label,
  value,
  onChange,
  register,
  error,
  helperText,
  minDate,
  maxDate,
  placeholder = '',
  shouldDisableDate,
  mainText = '',
  subTexts = [],
  isRequired = true
}) => {
  const { isJapanese } = useI18n()
  const [open, setOpen] = useState(false)

  return (
    <Wrapper>
      {label ? (
        <LabelWrapper>
          <Label htmlFor={label}>{label}</Label>
          <BasicLabel isRequired={isRequired}>
            {isJapanese ? '必須' : 'required'}
          </BasicLabel>
        </LabelWrapper>
      ) : null}
      {mainText ? <MainText>{mainText}</MainText> : null}
      {subTexts.length !== 0
        ? subTexts.map((subText) => <SubText>{subText}</SubText>)
        : null}
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ja}>
        <DatePicker
          value={value}
          onChange={onChange}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          minDate={minDate}
          maxDate={maxDate}
          shouldDisableDate={shouldDisableDate}
          renderInput={(params: any) => (
            <CustomTextField
              {...params}
              onClick={() => setOpen(true)}
              inputProps={{
                ...params.inputProps,
                placeholder,
                tabIndex: -1,
                disabled: true
              }}
              {...register}
              error={error}
              helperText={helperText}
            />
          )}
          mask="____/__/__ __:__"
        />
      </LocalizationProvider>
    </Wrapper>
  )
}
