import { styled } from '@mui/material/styles'
import { mediaQuery } from '../../functions/hooks'

const BaseVideoWrapper = styled('div')`
  aspect-ratio: 16/9;
  margin: 0 auto;
  width: 80%;
  ${mediaQuery('sp')} {
    margin: 0;
    padding: 0 15px;
    width: 100%;
  }
`

export type videoProps = {
  src: string
  width?: number | string
  height?: number | string
  title?: string
  frameBorder?: number
  allow?: string
  allowFullScreen?: boolean
}

export const Video: React.FC<videoProps> = ({
  src,
  width = '100%',
  height = '100%',
  title = 'no title',
  frameBorder = 0,
  allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
  allowFullScreen = false
}) => {
  return (
    <BaseVideoWrapper>
      <iframe
        width={width}
        height={height}
        src={src}
        title={title}
        frameBorder={frameBorder}
        allow={allow}
        allowFullScreen={allowFullScreen}
      />
    </BaseVideoWrapper>
  )
}
