import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'

/**
 *  「Fri Apr 14 2022 00:00:00 GMT+0900 (日本標準時)」の表示からyyyy/MM/ddに変換する
 *  ex) formatSlashDate(new Date("2022/04/14")) => 2022/04/14
 */
export const formatSlashDate = (date: Date) => {
  return format(date, 'yyyy/MM/dd', { locale: ja })
}

/**
 *  「Fri Apr 14 2022 00:00:00 GMT+0900 (日本標準時)」の表示からyyyy-MM-ddに変換する
 *  ex) formatDashDate(new Date("2022-04-14")) => 2022-04-14
 */
export const formatDashDate = (date: Date) => {
  return format(date, 'yyyy-MM-dd', { locale: ja })
}

/**
 *  「Fri Apr 14 2022 00:00:00 GMT+0900 (日本標準時)」の表示からyyyy-MM-ddに変換する
 *  ex) formatDashDate(new Date("2022-04-14")) => 2022-04-14
 */
export const formatDashDateWithTime = (date: Date) => {
  return format(date, 'yyyy-MM-dd HH:mm:ss', { locale: ja })
}

/**
 *  「Fri Apr 14 2022 00:00:00 GMT+0900 (日本標準時)」の表示からyyyy年M月d日(E)に変換する
 *  ex) formatJapaneseDate(new Date("2022-04-14")) => 2022年04月14日（月）
 */
export const formatJapaneseDate = (date: Date) => {
  return format(date, 'yyyy年M月d日 HH:mm', { locale: ja })
}
