import { axios } from '../../../utilities/axiosClient'

export type PasswordResetInput = {
  sent_token: string
}

/**
 * 新パスワード登録画面への遷移API
 * ユーザーがメールアドレスに添付されたURLをクリックすると発火する
 * sent_tokenが帰ってくるので、passwordResetAPIの引数にreset_tokenに設定する
 */

export const getPasswordResetInput = (
  token: string
): Promise<PasswordResetInput> => axios.get(`/api/reset/input/${token}`)
