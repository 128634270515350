import parse from 'html-react-parser'
import { BaseText } from '../../functions/themes'

export type BasicHtmlParseTextProps = {
  text: string
  className?: string
}

/**
 * XSSの危険性があるからdangerouslySetInnerHTMLでのパースを取りやめ
 * html-react-parserの機能をこのコンポーネントに閉じ込める
 */
export const BasicHtmlParseText: React.FC<BasicHtmlParseTextProps> = ({
  text,
  className
}) => {
  return <BaseText className={className}>{parse(text)}</BaseText>
}
