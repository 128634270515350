import { useCallback, useState } from 'react'
import MuiSnackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import { Alert } from '@mui/material'

export interface State extends SnackbarOrigin {
  open: boolean
}

/**
 * render-hooksパターンで作成
 * useClosure・useReactScrollのメインで吐き出すのは関数で
 * pagination・snackbarのメインはUIなので棲み分けができていると思う
 * UIがメイン、関数は副産物であるところが味噌
 */
export const Snackbar = () => {
  const [state, setState] = useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  })
  const { vertical, horizontal, open } = state

  const handleSnackbar = useCallback((newState: SnackbarOrigin) => {
    setState({ open: true, ...newState })
  }, [])

  const handleClose = () => {
    setState({ ...state, open: false })
  }

  const SnackbarComponent = () => {
    return (
      <MuiSnackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <Alert onClose={handleClose} sx={{ width: '600px' }}>
          注文カートに追加しました。
        </Alert>
      </MuiSnackbar>
    )
  }

  return { SnackbarComponent, handleSnackbar }
}
