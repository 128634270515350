import styled from '@emotion/styled'

/**
 * オーバーレイをかける対象にposition: relative;を仕込む必要がある
 */
export const BaseOverlay = styled('div')`
  background: rgba(74, 74, 74, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`
