export type newLintProps = {
  text: string
}

/**
 * 文字列に改行コードが含まれていた際に改行を行う
 */

export const TextNewLine: React.FC<newLintProps> = ({ text }) => {
  return (
    <p>
      {text.split('/(\n)/g').map((line) => (line === '\n' ? <br /> : line))}
    </p>
  )
}
