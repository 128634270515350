import { lazyRetry } from '../../functions/utilities'

const Top = lazyRetry(
  () => import(/* webpackChunkName: "en-top-page" */ '../../pages/Top')
)
const Landing = lazyRetry(
  () => import(/* webpackChunkName: "en-landing-page" */ '../../pages/Landing')
)
const Guidance = lazyRetry(
  () =>
    import(/* webpackChunkName: "en-guidance-page" */ '../../pages/Guidance')
)
const Login = lazyRetry(
  () => import(/* webpackChunkName: "en-login-page" */ '../../pages/Login')
)
const MachipassLogin = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "machipass-login-page" */ '../../pages/MachiPassLogin'
    )
)
const SettingProfile = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "setting-profile-page" */ '../../pages/SettingProfile'
    )
)
const Register = lazyRetry(
  () => import(/* webpackChunkName: "register-page" */ '../../pages/Register')
)
const Question = lazyRetry(
  () =>
    import(/* webpackChunkName: "en-question-page" */ '../../pages/Question')
)
const Contact = lazyRetry(
  () => import(/* webpackChunkName: "en-contact-page" */ '../../pages/Contact')
)
const PrivacyPolicy = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "en-privacy-policy-page" */ '../../pages/PrivacyPolicy'
    )
)
const Terms = lazyRetry(
  () => import(/* webpackChunkName: "en-terms-page" */ '../../pages/Terms')
)
const Error = lazyRetry(
  () => import(/* webpackChunkName: "en-error-page" */ '../../pages/Error')
)
const Complete = lazyRetry(
  () =>
    import(/* webpackChunkName: "en-complete-page" */ '../../pages/Complete')
)

export const commonRoutes = [
  {
    path: '/en/',
    element: <Top />
  },
  {
    path: '/en/lp',
    element: <Landing />
  },
  {
    path: '/en/tutorial',
    element: <Guidance />
  },
  {
    path: '/en/login',
    element: <Login />
  },
  {
    path: '/en/machi/link/login',
    element: <MachipassLogin />
  },
  {
    path: '/en/machi/link/login/complete',
    element: <Complete />
  },
  {
    path: '/en/additional/input',
    element: <SettingProfile />
  },
  {
    path: '/en/machi/link/complete',
    element: <Complete />
  },
  {
    path: '/en/register',
    element: <Register />
  },
  {
    path: '/en/question',
    element: <Question />
  },
  {
    path: '/en/resign/complete',
    element: <Complete />
  },
  {
    path: '/en/contact',
    element: <Contact />
  },
  {
    path: '/en/privacy',
    element: <PrivacyPolicy />
  },
  {
    path: '/en/terms',
    element: <Terms />
  },
  {
    path: '/en/error',
    element: <Error />
  }
]
