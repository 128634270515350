import { styled } from '@mui/material/styles'
import { UnorderedList } from '../../functions/types/Common'

const CustomUnorderedList = styled('ul')`
  display: grid;
  gap: 10px;
  list-style-type: inherit;
  padding: 0 20px;
  &.-gap5 {
    gap: 5px;
  }
`

type ContentUnorderedListProps = {
  handleClick: (actionName: string) => void
  unorderedLists: UnorderedList[]
  className?: string
}

export const ContentUnorderedList: React.FC<ContentUnorderedListProps> = ({
  handleClick,
  unorderedLists,
  className
}) => {
  return (
    <CustomUnorderedList className={className}>
      {unorderedLists.map((unorderedList) => (
        <li>
          {unorderedList.listItems.map((listItem) => (
            <>
              {listItem.action && (
                <span
                  onClick={() => handleClick(listItem.actionName)}
                  aria-hidden="true"
                >
                  {listItem.value}
                </span>
              )}
              {!listItem.action && listItem.value}
            </>
          ))}
        </li>
      ))}
    </CustomUnorderedList>
  )
}
