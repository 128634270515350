import React from 'react'
import ReactDOM from 'react-dom'
import './assets/sass/app.scss'
// import reportWebVitals from './reportWebVitals'
import { AppProvider } from './providers/app'
import { AppRoutes } from './routers/app'
// import * as serviceWorkerRegistration from './serviceWorkerRegistration'

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()

// serviceWorkerRegistration.register()
