import styled from '@emotion/styled'
import { BaseText } from './BaseText'

export const BaseOverlayText = styled(BaseText)`
  left: 50%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`
