import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { mediaQuery } from '../hooks'

/**
 * 上部に画像がくるタイプのカードレイアウト
 * 高さのみ固定する
 * カードとして仕様する采配ネガティブマージンを当ててコンテンツとの距離を計る
 * 画像とコンテンツの境界線としてborderを引く
 * ※voucherCardのみ特殊で直接コンポーネントで管理している
 */
export const BaseImageWithContentInCard = css`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px 10px 0 0;
  height: 170px;
  margin-bottom: -10px;
  object-fit: contain;
  width: 100%;
  ${mediaQuery('sp')} {
    border-bottom: none;
    border-radius: 0;
    height: 100px;
    width: 110px;
  }
`

export const BaseImageWithContentInPcCard = css`
  border-radius: 10px 10px 0 0; /* Add border radius here */
  height: 100%;
  left: 50%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`

/**
 * 画像のみ表示するタイプのカードレイアウト
 * カードとして仕様する采配ネガティブマージンを当ててコンテンツとの距離を計る
 * 画像との境界線は影で表現している
 */
export const BaseImageOnlyInCard = css`
  border-radius: 15px;
  height: 100%;
  left: 50%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  ${mediaQuery('sp')} {
    border-radius: 10px;
  }
`

/**
 * 基本的にはカード以外の画像に当てる
 * 仕様箇所が多いため高さは動的に変える
 */
export const BaseImageNormal = css`
  height: 110px;
  object-fit: contain;
  width: 100%;
  /* height:〇〇px */
  &.-shadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
`
