import cardValidator from 'card-validator'

// NOTE:数字以外を排除する
export const cleanInput = (value: string) => value.replace(/\D+/g, '')

// NOTE:スペースのような空白文字を排除する
export const joinedNumber = (value: string) => value.replace(/\s+/g, '')

export const getCardType = (value: string) => {
  const result = cardValidator.number(joinedNumber(value))
  return result.card ? result.card.type : ''
}

export const formatCardNumber = (value: string) => {
  const type = getCardType(value)
  const cleanValue = cleanInput(value)

  let currentValue

  switch (type) {
    case 'american-express':
      currentValue = `${cleanValue.slice(0, 4)} ${cleanValue.slice(
        4,
        10
      )} ${cleanValue.slice(10, 15)}`
      break
    case 'diners-club':
      currentValue = `${cleanValue.slice(0, 4)} ${cleanValue.slice(
        4,
        10
      )} ${cleanValue.slice(10, 14)}`
      break
    case 'jcb':
    case 'mastercard':
    case 'visa':
      currentValue = `${cleanValue.slice(0, 4)} ${cleanValue.slice(
        4,
        8
      )} ${cleanValue.slice(8, 12)} ${cleanValue.slice(12, 16)}`
      break
    default: // yupで弾く
      currentValue = cleanValue
      break
  }
  return currentValue.trim()
}

export const checkCardNumber = (value: string) => {
  return cardValidator.number(joinedNumber(value)).isValid
}

export const formatDate = (value: string) => {
  const cleanValue = cleanInput(value)
  const all = cleanValue.split('')
  if (all.length <= 2) {
    const joined = all.join('')
    return joined
  }
  all.splice(2, 0, '/')
  const sliced = all.length > 5 ? all.slice(0, 5) : all
  return sliced.join('')
}

export const checkDate = (value: string) => {
  const formatted = formatDate(value)
  return cardValidator.expirationDate(formatted).isValid
}

export const formatCcv = (cardNumber: string, cvv: string) => {
  const type = getCardType(cardNumber)
  const length = ['american-express'].includes(type) ? 4 : 3

  const cleanValue = cleanInput(cvv)
  const all = cleanValue.split('')
  const sliced = all.length > length ? all.slice(0, length) : all
  const formatted = sliced.join('')

  return formatted
}
