import { ReactNode, useRef, useCallback } from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import ArrowIcon from '../../assets/icon/icon_arrow.svg'

const RoundGradientButton = styled(Button)`
  /* stylelint-disable */
  position: relative;
  border-radius: 100rem;
  color: black;
  border: solid 3px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(
      101deg,
      #bed56e,
      #eac06c,
      #f1b169,
      #fc6456,
      #f94b59,
      #b77dc8
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px white inset; // NOTE:中の色をコントロールしている

  &.-grey {
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(101deg, #c4c4c4, #c4c4c4);
  }
  &::before {
    background: rgba(0, 0, 0, 0.2);
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
    z-index: 2;
  }

  &:hover::before {
    opacity: 0.2;
  }

  > span > .animation-icon {
    transition: 0.5s;
    transform: rotate(0deg);
  }
  &:hover > span > .animation-icon {
    transition: 0.5s;
    transform: rotate(90deg);
  }
`

interface ButtonRoundProps {
  children: ReactNode
  type?: 'button' | 'submit' | 'reset'
  className?: string
  isReversible?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const ButtonRound: React.FC<ButtonRoundProps> = ({
  className,
  children,
  type = 'button',
  isReversible = false,
  isDisabled,
  isLoading = false,
  onClick
}) => {
  // 多重送信防止用(3秒間はボタンをクリックできない)
  const processing = useRef(false)
  const handleButton = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (processing.current) return
      processing.current = true

      if (onClick) onClick(event)
      // // FIXME: ユーザーの動作環境が重たいことにより3秒以上待機することで、多重送信が発生する可能性がある
      setTimeout(() => {
        processing.current = false
      }, 3000)
    },
    [onClick]
  )

  return (
    <RoundGradientButton
      type={type}
      className={isReversible ? `-grey ${className}` : `${className}`}
      onClick={handleButton}
      disabled={isDisabled}
      startIcon={
        isReversible ? (
          <img src={ArrowIcon} alt="" className="animation-icon" />
        ) : null
      }
      endIcon={
        !isReversible ? (
          <img src={ArrowIcon} alt="" className="animation-icon" />
        ) : null
      }
    >
      {children}
    </RoundGradientButton>
  )
}
