import { axios } from '../../utilities/axiosClient'

export type ResignCompleteProps = {
  choice: number
  text?: string
}

/**
 * updateMarunouchiの退会処理
 * MachiPassは退会ならない
 */

export const getResignComplete = (params: ResignCompleteProps) =>
  axios.post('/api/resign/complete', params)
