import styled from '@emotion/styled'
import { mediaQuery } from '../../functions/hooks'

const FilterContainer = styled('div')<{ color: string }>`
  border-bottom: ${({ color }) => `1px solid ${color}`};
  width: 100%;
  ${mediaQuery('sp')} {
    border-bottom: none;
  }
`
const FilterNav = styled('nav')`
  display: flex;
  margin: auto;
  /*IE(Internet Explorer)・Microsoft Edgeへの対応*/
  -ms-overflow-style: none;
  overflow-x: auto;
  padding: 50px 10px 0 10px;
  /*Firefoxへの対応*/
  scrollbar-width: none;
  width: 80%;
  /*Google Chrome、Safariへの対応*/
  ::-webkit-scrollbar {
    display: none;
  }
  ${mediaQuery('sp')} {
    display: block;
    padding: 0;
    width: 100%;
  }
`
const FilterButton = styled('button')<{ color: string }>`
  background: white;
  border: ${({ color }) => `1px solid ${color}`};
  border-bottom: none;
  border-radius: 10px 10px 0 0;
  color: ${({ color }) => color};
  cursor: pointer;
  min-width: 220px;
  padding: 10px 15px;
  &.active {
    background: ${({ color }) => color};
    color: white;
    transition: 0.1s ease;
    transition-delay: 0.1s;
  }
  ${mediaQuery('sp')} {
    background: #f2f2f2;
    border: none;
    border-bottom: 1px solid white;
    border-radius: 0;
    color: black;
    width: 100%;
  }
  :hover {
    opacity: 0.7;
  }
`

interface FilterTabProps {
  currentFilter: string
  changeFilter: (newFilter: string) => void
  items: Array<string>
  color?: string
}

export const FilterTab: React.FC<FilterTabProps> = ({
  currentFilter,
  changeFilter,
  items,
  color = '#84bcb4'
}) => {
  const handleClick = (newFilter: string) => {
    changeFilter(newFilter)
  }
  return (
    <FilterContainer color={color}>
      <FilterNav>
        {items.map((item) => (
          <FilterButton
            key={item}
            onClick={() => handleClick(item)}
            className={currentFilter === item ? 'active' : ''}
            color={color}
          >
            {item}
          </FilterButton>
        ))}
      </FilterNav>
    </FilterContainer>
  )
}
