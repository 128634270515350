import { axios } from '../../utilities/axiosClient'

type VoucherUseProps = {
  id: number
}

/**
 * ワーカ限定特典を利用するときに使用する
 * id: 特典ID
 */
export const getVoucherUse = (params: VoucherUseProps) =>
  axios.post('/api/use_voucher', params)
