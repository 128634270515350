import { useQueryClient, useMutation } from 'react-query'
import { useErrorHandler } from 'react-error-boundary'
import { axios } from '../../utilities/axiosClient'

export type getMypageEditCompleteRequest = {
  last_name_jpn: string
  first_name_jpn: string
  last_name_kana: string
  first_name_kana: string
  last_name_eng: string
  first_name_eng: string
  gender_div: number
  building_id: number
  company: string
  workplace_id: number
  workplace_name_jpn?: string
  workplace_name_eng?: string
  position_id: number
  industry_type_id: number
  is_subscribed_to_newsletter: number
  action: string //back,confirmed
}

export const getMypageEditComplete = (params: getMypageEditCompleteRequest) =>
  axios.post('/api/edit/complete_method', {
    params
  })

export const useEditMypageComplete = () => {
  const queryClient = useQueryClient()
  const handleError = useErrorHandler()

  const editMypageCompleteMutation = useMutation(
    (params: getMypageEditCompleteRequest) =>
      axios.post('/api/edit/complete_method', params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getEditMypage')
      },
      onError: (error) => {
        handleError(error)
      }
    }
  )

  return { editMypageCompleteMutation }
}
