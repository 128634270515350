import { styled } from '@mui/material/styles'
import { mediaQuery } from '../hooks'

/**
 * 統一したタイトルのレイアウトを実装するために設置
 * 使用ファイルでインポートし必要であれば上書きでレイアウトを調整すること
 */
export const BaseTitle = styled('p')`
  color: #4a4a4a;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  &.-orange {
    color: orange;
  }
  &.-grey {
    color: #c4c4c4;
  }
  &.-blue {
    color: #edf7f9;
  }
  &.-white {
    color: white;
  }
  &.-left {
    text-align: left;
  }
  &.-small {
    font-size: 18px;
  }
  &.-sub {
    font-size: 26px;
  }
  ${mediaQuery('sp')} {
    font-size: 20px;
    &.-small {
      font-size: 18px;
    }
  }
  &.-line {
    white-space: pre-line;
  }
`
