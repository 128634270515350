import { ReactNode } from 'react'
import { Link, NavLink } from 'react-router-dom'
import MuiButton from '@mui/material/Button'
import { SxProps } from '@mui/material/styles'
import { MuiColorTypes } from '../../functions/types/Common'

type ButtonLinkProps = {
  path?: string
  children: ReactNode
  startIcon?: JSX.Element
  endIcon?: JSX.Element
  color?: MuiColorTypes | 'inherit'
  onClick?: () => void
  position?: 'start' | 'center' | 'end'
  className?: string
  sx?: SxProps
}

/**
 * MUIのLinkコンポーネントの場合iconの追加ができないためButtonを採用
 * 初期カラーを白にするためにvariant = secondaryを当てている
 * MUIのButtonをカスタムリンクで適用するとclassNameがうまく当たらないのでsxで対応
 */
export const ButtonLink: React.FC<ButtonLinkProps> = ({
  className,
  path = '/',
  children,
  startIcon = null,
  endIcon,
  color = 'inherit',
  position = 'center',
  onClick,
  sx
}) => {
  return (
    <MuiButton
      component={Link}
      to={path}
      color={color}
      size="large"
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      className={className}
      sx={{
        justifyContent: position,
        ...sx
      }}
    >
      {children}
    </MuiButton>
  )
}

/**
 * NavLinkの場合「active」というクラスの付与が可能になる
 * styledNameにお使い方に関してはProgressbarコンポーネントを見た方が良いかもしれない
 */
export const ButtonNavlink: React.FC<ButtonLinkProps> = ({
  path = '/',
  children,
  startIcon,
  endIcon,
  className,
  position,
  sx
}) => {
  return (
    <MuiButton
      component={NavLink}
      to={path}
      startIcon={startIcon}
      endIcon={endIcon}
      size="large"
      fullWidth
      className={className}
      sx={{
        justifyContent: position,
        ...sx
      }}
    >
      {children}
    </MuiButton>
  )
}
