import { styled } from '@mui/material/styles'
import { mediaQuery } from '../../functions/hooks'
import { BaseTitle } from '../../functions/themes'

const ContentFooterContainer = styled('div')`
  margin: 0 auto;
  padding: 40px 0;
  width: 100%;
  ${mediaQuery('sp')} {
    padding-bottom: 20px 0;
    width: 90%;
  }
`
const Inner = styled('div')`
  column-gap: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 10px;
  ${mediaQuery('sp')} {
    column-gap: 2%;
  }
`
const Title = styled(BaseTitle)`
  font-size: 20px;
  margin-bottom: 20px;
  ${mediaQuery('sp')} {
    margin-bottom: 10px;
  }
`

export type ContentFooterProps = {
  title: string
  contents: JSX.Element
}

/**
 * journal, voucher, eventの3つのコンテンツで使用している
 * ContentFooterの目的はコンポーネントとして切り出してスコープを限定するため
 * 可読性を高めるため
 */
export const ContentFooter: React.FC<ContentFooterProps> = ({
  title,
  contents
}) => {
  return (
    <ContentFooterContainer>
      <Title className="-left">{title}</Title>
      <Inner>{contents}</Inner>
    </ContentFooterContainer>
  )
}
