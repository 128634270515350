import {
  ScrollPosition,
  LazyLoadImage,
  trackWindowScroll
} from 'react-lazy-load-image-component'
import UpdateIcon from '../../assets/icon/icon_update_logo_center.svg'
// import 'react-lazy-load-image-component/src/effects/blur.css'

interface ImageProp {
  src?: string
  scrollPosition?: ScrollPosition
  className?: string
  onClick?: () => void
  height?: number
  handleImageOnload?: () => void
}

/**
 * 遅延ロード画像のレイアウトを調整するにはclassNameを親コンポーネントから流す必要あり
 * 下記を参考にしている（親からレイアウトを渡す系で使えそう）
 * https://emotion.sh/docs/@emotion/css
 */
const Image: React.FC<ImageProp> = ({
  src,
  scrollPosition,
  className,
  onClick,
  height,
  handleImageOnload
}) => {
  return (
    <LazyLoadImage
      src={src || UpdateIcon}
      // placeholderSrc={UpdateIcon}
      className={className || 'image'}
      scrollPosition={scrollPosition}
      draggable={false}
      height={height}
      onClick={onClick}
      delayTime={500}
      // beforeLoad={() => console.log('loading!')}
      afterLoad={handleImageOnload}
      threshold={2000}
      // effect="opacity"
    />
  )
}

// export default trackWindowScroll(Image)
export default Image
