import styled from '@emotion/styled'

type IconSizeKey = 'small' | 'medium' | 'large'

const ICON_SIZE: { [key in IconSizeKey]: string } = {
  small: '20px',
  medium: '30px',
  large: '40px'
} as const

export interface IconProps {
  content: string | JSX.Element
  size: IconSizeKey
  className?: string
}

const CustomSpan = styled('span')<{
  size: IconSizeKey
  ischecked?: boolean
}>`
  align-items: center;
  color: gray;
  display: flex;
  height: ${({ size }) => ICON_SIZE[size]};
  justify-content: center;
  &.-stepper {
    color: white;
  }
  &.-circle {
    color: inherit;
  }
`

const CustomParagraph = styled('p')<{
  size: IconSizeKey
  ischecked?: boolean
}>`
  align-items: center;
  background: white;
  border: 1px solid gray;
  border-radius: 20%;
  display: flex;
  font-size: 15px;
  height: ${({ size }) => ICON_SIZE[size]};
  justify-content: center;
  width: ${({ size }) => ICON_SIZE[size]};
  &.-stepper {
    background: none;
    border: none;
    border-radius: 50%;
    color: white;
  }
  &.-circle {
    background: white;
    border-radius: 50%;
    color: inherit;
  }
  &.-bg-black {
    background: gray;
  }
`

/**
 * inputCheckboxGroupで使用している
 */
export const SquareIcon: React.FC<IconProps> = ({
  content,
  size = 'medium',
  className
}) => (
  <CustomParagraph size={size} ischecked>
    <CustomSpan size={size} ischecked>
      {content}
    </CustomSpan>
  </CustomParagraph>
)
export const SquareIconBlank: React.FC<IconProps> = ({
  content,
  size = 'medium',
  className
}) => (
  <CustomParagraph size={size} ischecked>
    <CustomSpan size={size} ischecked>
      {content}
    </CustomSpan>
  </CustomParagraph>
)

/**
 * stepperで使用している
 */
export const StepperIcon: React.FC<IconProps> = ({
  content,
  size = 'medium',
  className
}) => (
  <CustomParagraph size={size} ischecked className="-stepper">
    <CustomSpan size={size} ischecked className="-stepper">
      {content}
    </CustomSpan>
  </CustomParagraph>
)

/**
 * inputRadioGroupで使用している
 * classNameは一旦下記でのみ使用している背景を変えるときに使う
 */
export const CircleIcon: React.FC<IconProps> = ({
  content,
  size = 'medium',
  className
}) => (
  <CustomParagraph size={size} ischecked className={`${className} -circle`}>
    <CustomSpan size={size} ischecked className="-circle">
      {content}
    </CustomSpan>
  </CustomParagraph>
)
export const CircleIconBlank: React.FC<IconProps> = ({
  content,
  size = 'medium',
  className
}) => (
  <CustomParagraph size={size} ischecked className={`${className} -circle`}>
    <CustomSpan size={size} ischecked className="-circle">
      {content}
    </CustomSpan>
  </CustomParagraph>
)
