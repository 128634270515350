import { styled } from '@mui/material/styles'
import { Box, Theme } from '@mui/material'
import { mediaQuery } from '../hooks'

export const BaseBox = styled(Box)<{ theme?: Theme }>`
  background: ${({ theme }) => theme.palette.base.background};
  display: flex;
  margin-top: 90px;
  min-height: calc(100vh - 160px - 320px);
  &.-public {
    min-height: calc(100vh - 90px - 320px);
  }
  &.-voucher {
    background: ${({ theme }) => theme.palette.voucher.background};
  }
  &.-event {
    background: ${({ theme }) => theme.palette.event.background};
  }
  &.-journal {
    background: ${({ theme }) => theme.palette.journal.background};
  }
  &.-marudeli {
    background: ${({ theme }) => theme.palette.marudeli.background};
    min-height: calc(100vh - 160px - 510px);
  }
  &.-marudeli-procedure {
    background: ${({ theme }) => theme.palette.base.main};
    min-height: calc(100vh - 160px - 510px);
    ${mediaQuery('sp')} {
      background: white;
    }
  }
  &.-auth {
    background: ${({ theme }) => theme.palette.auth.background};
    ${mediaQuery('sp')} {
      background: linear-gradient(
        180deg,
        #ffffff 25.68%,
        rgba(255, 255, 255, 0.93) 40.39%,
        rgba(250, 246, 241, 0.62) 66.71%,
        #faf6f1 88%
      );
    }
  }
  &.-block {
    display: block;
  }
  ${mediaQuery('sp')} {
    /* voucherDetailはsp時に白くするっぽい */
    /* background: white; */
    display: block;
    margin-top: 70px;
  }
`
