import MuiStepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import styled from '@emotion/styled'
import { StepIconProps } from '@mui/material/StepIcon'
import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector'
import { StepperIcon } from './BasicIcon'
import { mediaQuery } from '../../functions/hooks'
import { BaseText } from '../../functions/themes'

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  marginLeft: 20,
  marginRight: 20,
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#444'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#444'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    backgroundColor: '#eaeaf0',
    border: 0,
    borderRadius: 1,
    height: 3
  }
}))

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean }
}>`
  align-items: center;
  background-color: ${({ ownerState }) =>
    ownerState.active || ownerState.completed ? '#444' : '#ccc'};
  border-radius: 50%;
  color: #fff;
  display: flex;
  height: 50px;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  width: 50px;
  z-index: 1;
  ${mediaQuery('sp')} {
    height: 35px;
    width: 35px;
  }
`

const CustomStepLabel = styled(StepLabel)`
  background: transparent;
  ${mediaQuery('sp')} {
    background: transparent;
  }
`

/*eslint-disable react/destructuring-assignment*/
const ColorlibStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props

  const icons: { [index: string]: React.ReactElement } = {
    1: <StepperIcon content="1" size="large" />,
    2: <StepperIcon content="2" size="large" />,
    3: <StepperIcon content="3" size="large" />,
    4: <StepperIcon content="4" size="large" />
  }

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

interface BasicStepperProps {
  activeStep: number
  steps: string[]
}

export const Stepper: React.FC<BasicStepperProps> = ({ activeStep, steps }) => {
  return (
    <MuiStepper
      activeStep={activeStep}
      alternativeLabel
      connector={<ColorlibConnector />}
    >
      {steps.map((step) => (
        <Step key={step}>
          <CustomStepLabel StepIconComponent={ColorlibStepIcon}>
            <BaseText>{step}</BaseText>
          </CustomStepLabel>
        </Step>
      ))}
    </MuiStepper>
  )
}
