import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { getVoucherCategoryAndArea } from '../apis'
import { SmallArea, Category, SmallCategory, Area } from '../types/Voucher'
import { useI18n } from '.'
import BUILDING_NAME from '../constants/voucher/buildingName.json'
import BUILDING_NAME_EN from '../constants/voucher/buildingNameEn.json'

enum CATEGORY_TYPE {
  CATEGORY,
  AREA
}

export type AreaOption = {
  big_area_id: number
  value: string
  label: string
}

export type CategoryWithOneSmallCategory = Omit<
  Category,
  'small_categories'
> & {
  small_category: SmallCategory
}

export type AreaWithOneSmallArea = Omit<Area, 'small_areas'> & {
  small_area: SmallArea
}

export type categoryOption = {
  value: string
  label: string
  bigValue: string
  bigLabel: string
  categoryIntegrationFlag: string
}

export type bigHasManySmallCategory = {
  value: string
  label: string
  categoryOptions: categoryOption[]
}

const MAINAREA = 1

/* eslint-disable no-param-reassign */
export const useVoucherSearchOptions = () => {
  const { isJapanese } = useI18n()
  const { data } = useQuery(
    'getVoucherCategoryAndArea',
    getVoucherCategoryAndArea
  )

  const categoryData = useMemo(
    () => (data ? data[CATEGORY_TYPE.CATEGORY] : []),
    [data]
  )
  const areaData = useMemo(() => (data ? data[CATEGORY_TYPE.AREA] : []), [data])

  const formatCategory = useMemo(() => {
    return categoryData.reduce<CategoryWithOneSmallCategory[]>(
      (prev, current) => {
        const { small_categories, ...rest } = current
        const results = small_categories.map((category) => {
          return { ...rest, small_category: category }
        })
        prev = [...prev, ...results]
        return prev
      },
      []
    )
  }, [categoryData])

  const categoryOptions = useMemo(() => {
    return formatCategory.map((category) => {
      return {
        value: String(category.small_category.id) || '',
        label: category.small_category.small_category_name || '',
        bigValue: String(category.id) || '',
        bigLabel: category.big_category_name,
        categoryIntegrationFlag: String(category.category_integration_flag)
      }
    })
  }, [formatCategory])

  const formatArea = useMemo(() => {
    const results = areaData.reduce(
      (prev: AreaWithOneSmallArea[], current: Area) => {
        const { small_areas, ...rest } = current
        const results = small_areas!.map((area) => {
          return { ...rest, small_area: area }
        })
        prev = [...prev, ...results]
        return prev
      },
      [] as AreaWithOneSmallArea[]
    )
    return results
  }, [areaData])

  const areaOptions: AreaOption[] = useMemo(() => {
    const formatAreaRemoveMain = formatArea.filter(
      (area) => area.small_area.area_div !== MAINAREA
    )
    return formatAreaRemoveMain.map((area) => {
      return {
        big_area_id: area.small_area.big_area_id,
        value: String(area.small_area.id) || '',
        label: area.small_area.small_area_name || ''
      }
    })
  }, [formatArea])

  const buildingName = isJapanese ? BUILDING_NAME : BUILDING_NAME_EN

  const allBuildingIds = buildingName.map((item) => String(item.id))

  const allAreaIds = areaOptions.map((item) => item.value)

  const bigCategories = useMemo(() => {
    const formatBigCategories = categoryOptions.map((category) => {
      return {
        value: category.bigValue,
        label: category.bigLabel
      }
    })
    const uniqueBigCategories = Array.from(
      new Map(
        formatBigCategories.map((category) => [category.value, category])
      ).values()
    )

    return uniqueBigCategories
  }, [categoryOptions])

  const bigHasManySmallCategories: bigHasManySmallCategory[] =
    bigCategories.map((bigCategory): bigHasManySmallCategory => {
      const newArray: categoryOption[] = categoryOptions.filter(
        (data: categoryOption): boolean => {
          return data.bigValue === bigCategory.value
        }
      )

      const bigHasManySmallCategories: bigHasManySmallCategory = {
        value: bigCategory.value,
        label: bigCategory.label,
        categoryOptions: newArray
      }

      return bigHasManySmallCategories
    })

  return {
    categoryOptions,
    areaOptions,
    allBuildingIds,
    allAreaIds,
    BUILDING_NAME,
    BUILDING_NAME_EN,
    bigCategories,
    bigHasManySmallCategories
  }
}
