import axios from 'axios'

type Holidays = {
  data: {
    [key: string]: string
  }
}
/**
 * エンドポイントが違うので素のaxiosを使用している
 * 返り値は他にもあるけど必要な箇所だけ定義している
 */
export const getHoliday = (): Promise<Holidays> =>
  axios.get('https://holidays-jp.github.io/api/v1/date.json')
