import { styled } from '@mui/material/styles'
import { OrderedList } from '../../functions/types/Common'
import { ContentParagraph } from './ContentParagraph'

const CustomOrderedList = styled('ol')`
  display: grid;
  gap: 10px;
  padding: 0 20px;
`
const CustomParagraphWrapper = styled('div')`
  display: grid;
  gap: 10px;
  padding: 20px 0;
`

type ContentOrderedListProps = {
  handleClick: (actionName: string) => void
  orderedLists: OrderedList[]
}

export const ContentOrderedList: React.FC<ContentOrderedListProps> = ({
  handleClick,
  orderedLists
}) => {
  return (
    <CustomOrderedList>
      {orderedLists.map((orderedList) => (
        <li>
          {orderedList.listItems.map((listItem) => (
            <>
              {listItem.action && (
                <span
                  onClick={() => handleClick(listItem.actionName)}
                  aria-hidden="true"
                >
                  {listItem.value}
                </span>
              )}
              {!listItem.action && listItem.value}
            </>
          ))}
          {/* 拡張させすぎかもしれないので運用しやすさも加味してコンポーネント化するか検討 */}
          {orderedList?.paragraphs && orderedList.paragraphs.length !== 0 && (
            <CustomParagraphWrapper>
              <ContentParagraph
                paragraphs={orderedList.paragraphs}
                handleClick={handleClick}
              />
            </CustomParagraphWrapper>
          )}
        </li>
      ))}
    </CustomOrderedList>
  )
}
