import { styled } from '@mui/material/styles'
import { mediaQuery } from '../hooks'

/* templateでのみ使用する */
export const BaseContainer = styled('div')`
  margin: 0 auto;
  width: 60%;
  word-break: break-all;
  ${mediaQuery('sp')} {
    width: 90%;
  }
`
