import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup
} from '@mui/material'
import styled from '@emotion/styled'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import CheckIcon from '@mui/icons-material/Check'
import {
  ErrorText,
  SquareIcon,
  SquareIconBlank,
  BasicLabel,
  InputTextarea
} from '.'
import { BaseText } from '../../functions/themes'
import { useI18n } from '../../functions/hooks'
import { MuiColorTypes } from '../../functions/types/Common'

const Wrapper = styled(FormControl)`
  display: grid;
  gap: 5px;
`
const SubText = styled(BaseText)`
  font-size: 12px;
`
const MainText = styled(BaseText)`
  font-weight: bold;
`
const LabelWrapper = styled('div')`
  align-items: center;
  display: flex;
  gap: 5px;
`
const Label = styled('label')`
  font-size: 15px;
  font-weight: bold;
`
const CustomFormControlLabel = styled(FormControlLabel)`
  display: block;
  margin-left: 0;
  margin-right: 0;
`
const CustomCheckBox = styled(Checkbox)`
  min-height: 38px;
  min-width: 38px;
  padding: 5px;
`

export interface CheckboxGroupProps {
  label?: string | ReactJSXElement
  isDisabled?: boolean
  checkboxProps: CheckboxProps[]
  selectedCheckbox: string[]
  size?: 'small' | 'medium'
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  mainText?: string
  subTexts?: string[] //複数入ることを想定
  isRequired?: boolean
  isRow?: boolean
  colorTypes?: MuiColorTypes
  // NOTE:エラーハンドリング
  error?: boolean
  helperText?: string
  // NOTE:custom icon
  icon?: JSX.Element
  checkedIcon?: JSX.Element
  name?: string
  onChangeText?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void // その他の選択肢で使用
  othersTextValue?: string // その他の選択肢で使用
  // control?: Control<MarudeliProcedureDeliveryFormData>
}

export type CheckboxProps = {
  value: string
  label: string
}

/**
 * RHFと連携させるためにregisterではなくcontrolを使う点、配列になる点、0808時点で
 * どのような値を送るか不明な点を考慮してもローカルで管理して良いと思う
 */
export const InputCheckboxGroup: React.FC<CheckboxGroupProps> = ({
  error = false,
  helperText = '',
  label = '',
  isDisabled = false,
  size = 'small',
  checkboxProps,
  selectedCheckbox,
  onChange,
  mainText = '',
  subTexts = [],
  isRequired = true,
  isRow = false,
  colorTypes = 'point',
  icon = <SquareIcon content="" size={size} />,
  checkedIcon = (
    <SquareIconBlank
      content={<CheckIcon fontSize="small" color={colorTypes} />}
      size={size}
    />
  ),
  name,
  onChangeText,
  othersTextValue
}) => {
  const { isJapanese } = useI18n()

  return (
    <Wrapper>
      {label ? (
        <LabelWrapper>
          <Label htmlFor="">{label}</Label>
          <BasicLabel isRequired={isRequired}>
            {isJapanese ? '必須' : 'required'}
          </BasicLabel>
        </LabelWrapper>
      ) : null}
      {mainText ? <MainText>{mainText}</MainText> : null}
      {subTexts.length !== 0
        ? subTexts.map((subText) => <SubText key={subText}>{subText}</SubText>)
        : null}
      <FormGroup row={isRow}>
        {checkboxProps.map((checkbox, index) => (
          <>
            <CustomFormControlLabel
              control={
                <CustomCheckBox
                  onChange={onChange}
                  checked={selectedCheckbox.includes(checkbox.value)}
                  disabled={isDisabled}
                  size={size}
                  value={checkbox.value}
                  name={name}
                  icon={icon}
                  checkedIcon={checkedIcon}
                />
              }
              label={checkbox.label}
              labelPlacement="start"
              key={checkbox.value}
            />
            {checkbox.label === 'その他' &&
              selectedCheckbox.includes(checkbox.value) && (
                <InputTextarea
                  key={`${checkbox.value}${String(index)}`}
                  onChange={onChangeText}
                  value={othersTextValue}
                />
              )}
          </>
        ))}
      </FormGroup>
      <ErrorText error={error} helperText={helperText} />
    </Wrapper>
  )
}
