import { axios } from '../../utilities/axiosClient'

export type AdditionalInfo = {
  marunouchiFlg?: string
  // update!Marunouchiのアカウントが既に存在している場合
  message?: string
  // アカウントが無く、追加情報入力画面に遷移させる必要がある場合
  pos?: Record<string, string> // 役職(役員、部長、課長など)
  it?: Record<string, string> // 職種(営業、事務、経営企画など)IndustryTypeの略
  token?: string
  expire?: string
  refresh_token?: string
  user_api_response?: UserApiResponse // MachiPassに登録している情報
}

export type UserApiResponse = {
  birthdate: string
  email: string
  family_name: string
  family_name_kana: string
  given_name: string
  given_name_kana: string
  sub: string
}

export type AdditionalInfoProps = {
  state: string
  code: string
}

/**
 * machipassの情報が少ない＞update丸の内でさらに追加して情報をアップデートする
 * (既MachiPassユーザー・新規MachiPassユーザーを問わず)新規登録後に追加情報登録画面にリダイレクトさせる
 * 追加情報入力画面/追加情報確認画面は作成する必要がある
 */
export const getAdditionalInfo = (
  params: AdditionalInfoProps
): Promise<AdditionalInfo> =>
  axios.get(`/api/additional/input?state=${params.state}&code=${params.code}`)
