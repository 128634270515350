import { axios } from '../../utilities/axiosClient'

type QuestionnaireProps = {
  count?: number
}

export type Questionnaire = {
  total_count: number
  current_page: number
  total_pages: number
  questionnaires: Array<Questionnaires>
}
export type Questionnaires = {
  id: number
  title?: string
  lead?: string
  start_datetime?: string
  end_datetime?: string
  thumbnail_url?: string
  image_url?: string
  detail_url?: string
  answered?: boolean
}

export const getQuestionnaire = (
  params?: QuestionnaireProps
): Promise<Questionnaire> => axios.post('/api/questionnaire', params)
