import { axios } from '../../../utilities/axiosClient'

export type PasswordReset = {
  password: string
  password2: string
  reset_token: string
}

/**
 * 非ログイン時のパスワード再設定完了API
 * ユーザーにパスワード変更完了メールを送付し、
 * 処理終了後はパスワード再設定完了ページをフロント側で表示する
 */

export const getPasswordReset = (params: PasswordReset) =>
  axios.post('/api/reset', params)
