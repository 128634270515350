import { styled } from '@mui/material/styles'
import { mediaQuery } from '../hooks'

/**
 * 統一したタイトルのレイアウトを実装するために設置
 * 使用ファイルでインポートし必要であれば上書きでレイアウトを調整すること
 */
export const BaseButtonWrapper = styled('div')`
  display: flex;
  gap: 30px;
  justify-content: center;
  ${mediaQuery('sp')} {
    align-items: center;
    flex-direction: column-reverse;
    gap: 20px;
    margin: 0 auto;
    width: -moz-fit-content; /* Firefox */
    width: fit-content; /* other browsers */
  }
`
