import { axios } from '../../utilities/axiosClient'
import { UserApiResponse } from './additionalInfo'

export type AdditionalInfoConfirm = {
  data: AdditionalInfoResponse
}
export type AdditionalInfoResponse = {
  // #FIXME: 型の修正が必要
  gender_div: number
  building_id: number
  workplace_id: number
  position_id: number
  industry_type_id: number
  company?: string
  access_token: string
  refresh_token: string
  expire: string
  building: string
  industry_type: string
  workplace: string
  position: string
  last_name_kana?: string
  first_name_kana?: string
  birth_date: string
  birth_year: string
  birth_month: string
  birth_day: string
  workplace_name_jpn?: string
  workplace_name_eng?: string
  last_name_jpn?: string
  first_name_jpn?: string
  last_name_eng?: string
  first_name_eng?: string
  pre_token?: string
}

export type AdditionalInfoConfirmProps = {
  // 追加情報入力からの情報
  gender_div: number // 1(男性),2(女性),3(その他)
  building_id: number
  workplace_id: number
  company: string
  position_id: number
  industry_type_id: number
  workplace_name_jpn?: string
  workplace_name_eng?: string
  // MachiPassAPIのレスポンスからの情報
  access_token: string
  refresh_token: string
  expire: string
  user_api_response: UserApiResponse
}

/**
 * machipassの情報が少ない＞update丸の内でさらに追加して情報をアップデートする
 * 確認画面からはlink/complete(post)で紐付け処理を完了させる
 */
export const getAdditionalInfoConfirm = (
  params: AdditionalInfoConfirmProps
): Promise<AdditionalInfoConfirm> =>
  axios.post('/api/additional/confirm', params)
