import { axios } from '../../utilities/axiosClient'
import { Building } from '../../types/Common'

export type Buildings = {
  building: Building[]
}

/**
 * プルダウン用の就業ビル一覧を取得する
 * Mypageのprofile更新で使用する
 */
export const getBuilding = (): Promise<Buildings> => axios.get('/api/building')
