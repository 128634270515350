import MuiDivider from '@mui/material/Divider'
import { ReactNode } from 'react'

type DividerProps = {
  variant?: 'fullWidth' | 'inset' | 'middle'
  children?: ReactNode
}

/**
 * https://mui.com/material-ui/react-divider/
 * https://mui.com/material-ui/api/divider/
 */
export const Divider: React.FC<DividerProps> = ({
  variant = 'middle',
  children
}) => {
  return (
    <MuiDivider
      variant={variant}
      sx={{
        marginTop: () => (variant === 'middle' ? '10px' : '0px'),
        marginBottom: () => (variant === 'middle' ? '10px' : '0px')
      }}
    >
      {children}
    </MuiDivider>
  )
}
