import { axios } from '../../../utilities/axiosClient'

export type SettingEmailConfirm = {
  email: string
  email2: string
}

export type ResponseEmailConfirm = {
  email: string
  email2: string
}

/**
 * メールアドレス変更確認用
 * このconfirmAPIでサーバー側でバリデーションを行なっている
 */
export const getSettingEmailConfirm = (
  params: SettingEmailConfirm
): Promise<ResponseEmailConfirm> => axios.post('/api/mail_edit/confirm', params)
