import { styled } from '@mui/material/styles'
import { mediaQuery } from '../hooks'

export const PairWrapper = styled('div')`
  background: white;
  display: grid;
  gap: 10px;
`
export const PairTitleWrapper = styled('div')`
  align-items: center;
  background: #f9f9f9;
  display: flex;
  padding: 5px 20px;
  ${mediaQuery('tab')} {
    display: block;
  }
`
export const PairTextWrapper = styled('div')`
  margin: auto 20px;
  &.-pointer {
    cursor: pointer;
  }
`
