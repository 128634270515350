import { css } from '@emotion/css'
import { Image } from '../../components/uis'
import AmexIcon from '../../assets/image/credit/amex.png'
import DinersIcon from '../../assets/image/credit/diners.png'
import JcbIcon from '../../assets/image/credit/jcb.png'
import MasterIcon from '../../assets/image/credit/master.png'
import VisaIcon from '../../assets/image/credit/visa.png'
import { mediaQuery } from '.'
import { getCardType } from '../utilities'

type keyType =
  | 'american-express'
  | 'diners-club'
  | 'jcb'
  | 'mastercard'
  | 'visa'

const styledImage = css`
  width: 70px;
  &.-large {
    width: 80px;
  }
  ${mediaQuery('sp')} {
    width: 20px;
    &.-large {
      width: 30px;
    }
  }
`

export const useCreditCard = () => {
  const getCardBrancdIcon = (cardNumber: string): JSX.Element => {
    const type = getCardType(cardNumber.substring(0, 2)) as keyType

    const icons: { [key in keyType]: JSX.Element } = {
      'american-express': <Image src={AmexIcon} className={styledImage} />,
      'diners-club': <Image src={DinersIcon} className={styledImage} />,
      jcb: <Image src={JcbIcon} className={styledImage} />,
      mastercard: (
        <Image src={MasterIcon} className={`${styledImage} -large`} />
      ),
      visa: <Image src={VisaIcon} className={`${styledImage} -large`} />
    }

    return icons[type]
  }

  return { getCardBrancdIcon }
}
